import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import MobileNavBar from "./MobileNavBar";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  SearchIcon,
  HomeIcon,
  CameraRellIcon,
  NotificationIcon,
  MessageIcon,
} from "../../constant/IconsPath";
import { logo4, profile } from "../../constant/imagePath";
import { companyLogo3 } from "../../constant/LogoPath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import users from "../../data/AllUsers";
import { useSelector } from "react-redux";
import Notifications from "../Models/Notification/Notifications";
import "../../style/Header.css";
import StorageService from "../../services/StorageService";
import { post } from "../../api/api";

const Header = ({
  loginAndSignup,
  messageShow,
  sarchBarShow = true,
  cameraShow,
  sendIconShow,
  notificationNotShow,
  setMessageForLogin,
  messageCurrentPage
}) => {
  const [toggle, setToggle] = useState(false);
  const [findUser, setFindUser] = useState([]);
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [passInputVal, setPassInputVal] = useState("");
  const [isShowNotification, setIsShowNotification] = useState(false);
  const notification1 = useSelector((state) => state.notification);
  const [numberOfNotification, setNumberOfNotification] = useState(0);
  const [photoURL, setPhotoURL] = useState();
  const [token, setToken] = useState("");
  const [userName, setUserName] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const someValue = StorageService.getValue('userName');
  const someProfile = StorageService.getValueProfilePic('profilePic');
  useEffect(() => {
    if (someValue) {
      setUserName(someValue);
    }
    if (someProfile) {
      setProfilePic(someProfile);
    }
  }, [])

  const navigate = useNavigate();
  const notification = useRef();
  const closeSearchResult = useRef();

  useEffect(() => {
    setToken(StorageService.getJwt());
  }, [!token]);

  useEffect(() => {
    let unreadNotificationsCount = notification1.reduce((count, val) => {
      if (val.status === "unread") {
        return count + 1;
      }
      return count;
    }, 0);
    setNumberOfNotification(unreadNotificationsCount);
  }, [notification1]);
  
  useEffect(() => {
    let handler1 = (e) => {
      if (
        closeSearchResult.current &&
        !closeSearchResult.current.contains(e.target)
      ) {
        setFindUser([]);
        setSearchFieldValue("");
      }
    };
    document.addEventListener("mousedown", handler1);
    return () => {
      document.addEventListener("mousedown", handler1);
    };
  }, []);

  useEffect(() => {
    let handler = (e) => {
      if (notification.current && !notification.current.contains(e.target)) {
        setIsShowNotification(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, []);

  const SearchUser = (e) => {
    if (e.trim().length !== 0 || passInputVal !== "") {
      setPassInputVal(e);
      setSearchFieldValue(e);
      if (e === "") {
        setFindUser([]);
      }
    }
  };

  useEffect(()=>{
    const debounce = setTimeout(() => {
      if(passInputVal){
        getAllUser(passInputVal)
      }
    }, 500);
    return () => clearTimeout(debounce);
  },[passInputVal])

  const getAllUser= async(e)=>{
    let res = await post(`/Identity/SearchUsers?username=${e}`)
    let data=await res.json();
    console.log("res",);
    if(data&&data?.length>0){
      setFindUser(data?.slice(0,10))
    }
  }

  const searchPeople = (event) => {
    if (searchFieldValue !== "" && searchFieldValue.length > 0 && searchFieldValue !== undefined && searchFieldValue !== null) {
      event.preventDefault();
      navigate("/search-result", { state: passInputVal });
      setFindUser([]);
      setSearchFieldValue("");
    } else {
      let searchArea = document.querySelector(".search_area");
      searchArea.focus();
    }
  };

  const searchPeople1 = () => {
    setFindUser([]);
    navigate("/search-result", { state: passInputVal });
    setSearchFieldValue("");
  };

  const navigateAnotherPage = (val) => {
    setFindUser([]);
    navigate("/userProfile", { state: val });
  };

  const showNotification = () => {
    if (!notificationNotShow) {
      setIsShowNotification(!isShowNotification);
    }
  }

  const showLoginMessage = () => {
    if (setMessageForLogin) {
      setMessageForLogin(true);
    }
  }


  return (
    <header
      className="py-4"
      style={{
        top: "0",
        width: "100%",
        position: "fixed",
        zIndex: "99",
        background: "#131313",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 36px 69px 0px",
      }}
    >
      <div className="container mx-auto">
        <nav className="hidden items-center justify-between sm:flex">
          <div className="logo w-[161px]">
            <Link to={ "/"}>
              <img className="w-[161px]" src={companyLogo3} alt="logo" width={"auto"} height={"auto"} />
            </Link>
          </div>

          <div className="flex items-center justify-center gap-4 ml-7">
            {/* search bar */}
            {sarchBarShow && (
              <div className="flex items-center justify-center">
                <Link to={"/"}>
                  <img
                    src={HomeIcon}
                    alt="Home icon"
                    className="text-primary text-4xl"
                    width={"auto"}
                    height={"auto"}
                  />
                </Link>

                <form className="px-[18px] min-[920px]:w-[300px] lg:w-[755px]  relative" ref={closeSearchResult} style={{ marginRight: "1.5rem" }}>
                  <input
                    placeholder="Search Members"
                    type="text"
                    className="border border-lightGray rounded-full px-[14px] py-[9px] w-full search_area"
                    style={{
                      border: "1px solid rgba(199, 199, 199, 0.5)",
                      outline: "none",
                      background: "transparent"
                    }}
                    onChange={(e) => loginAndSignup ? showLoginMessage() : SearchUser(e.target.value)}
                    value={searchFieldValue}
                  />
                  <button
                    type="button"
                    className="w-[30px] h-[30px] flex items-center justify-center absolute top-1/2 right-[10px] -translate-x-1/2 -translate-y-1/2 bg-primary rounded-full"
                    onClick={(e) => loginAndSignup ? showLoginMessage() : searchPeople(e)}
                  >
                    <img
                      src={SearchIcon}
                      alt="search icon"
                      className="text-primary text-4xl"
                      width={"auto"}
                      height={"auto"}
                    />
                  </button>

                  {/* search result drop down */}
                  {searchFieldValue && passInputVal && (
                    <div className="searchResultInBox">
                      <div
                        className="search_result_top_label"
                        onClick={searchPeople1}
                      >
                        <div className="magnifyning_glass">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </div>
                        <p>
                          Search for{" "}
                          <span
                            style={{
                              color: "#fff",
                              lineHeight: "normal",
                              fontWeight: "bold",
                            }}
                          >
                            "{searchFieldValue}"
                          </span>
                        </p>
                      </div>
                      {findUser?.length > 0 &&
                        findUser.map((val) => {
                          let { id, userName, profilePicture } = val;
                          return (
                            <div
                              className="user1"
                              key={id}
                              onClick={() => navigateAnotherPage(id)}
                            >
                              <img src={profilePicture?`data:image/png;base64,${profilePicture}`:logo4} alt="userImage" />
                              <p><span style={{ color: "#fff" }}>
                                {userName}
                              </span></p>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </form>
              </div>
            )}
            <div className="flex items-center justify-center gap-[3.25rem] loginAndSignUp">
              
              {(token !== null || token) ? (
                <>
                </>
              ) : (
                <>
                  <NavLink to="/login" className="Headerlogin">
                    Login
                  </NavLink>
                </>
              )
              }
            </div>

            {/* social Link */}
            {loginAndSignup ? (
              <div className="flex items-center justify-center gap-[3.25rem] loginAndSignUp">
                <NavLink to="/signUp" className="HeaderSignup">
                  Sign Up
                </NavLink>
              </div>
            ) : (
              <div className="flex items-center justify-center gap-[2rem]">
                {cameraShow && (
                  <Link to="/">
                    <img
                      src={CameraRellIcon}
                      alt="Camera Rell"
                      className="text-[#444444] text-3xl"
                    />
                  </Link>
                )}
                <div
                  onClick={() => setToggle(!toggle)}
                  className="logo flex items-center justify-center cursor-pointer"
                >
                  <h2 className="text-[17px] ml-1 "><span style={{ color: "#fff" }}>{userName && userName}</span></h2>
                  <IoIosArrowDown className="text-secondary text-sm ml-1" style={{ color: "#fff" }} />
                </div>
              </div>
            )}
          </div>
        </nav>

        {/* mobile navigation */}
        <MobileNavBar
          open={toggle}
          toggle={() => setToggle(!toggle)}
          messageShow={messageShow}
          loginAndSignup={loginAndSignup}
          sendIconShow={sendIconShow}
          messageCurrentPage={messageCurrentPage}
          showLoginMessage={showLoginMessage}
        />
      </div>
    </header>
  );
};

export default Header;
