import React, { useRef, useState } from 'react';

// Icon
import { faAngleDown, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchIcon } from '../../../constant/IconsPath';

// image
import { vg_image1, vg_image2, vg_image3, vg_image4, vg_image5 } from '../../../constant/imagePath'

// style
import "./ViewAllGroup.css";

const ViewAllGroup = ({ open, setViewGroup }) => {
    const [isShowDropDown, setIsShowDropDown] = useState(false);
    const [text, setText] = useState(["Top groups", "Joined groups"]);
    const [activeText, setActiveText] = useState("All");
    const [allViewGroup, setAllViewGroup] = useState([]);

    const data = [
        {
            id: 1,
            img: vg_image1,
            title: "Sci-fi & Adventure",
            description: "Private · 1M members · 10+ posts a day",
            type: "Top groups"
        },
        {
            id: 2,
            img: vg_image2,
            title: "Cool Group Number 2",
            description: "Private · 1M members · 10+ posts a day",
            type: "Joined groups"
        },
        {
            id: 3,
            img: vg_image3,
            title: "Horror New Movies",
            description: "Private · 1M members · 10+ posts a day",
            type: "Top groups"
        },
        {
            id: 4,
            img: vg_image4,
            title: "Fantasy Movies",
            description: "Private · 1M members · 10+ posts a day",
            type: "Joined groups"
        },
        {
            id: 5,
            img: vg_image5,
            title: "Action & Thriller",
            description: "Private · 1M members · 10+ posts a day",
            type: "Joined groups"
        }
    ];

    const modalRef = useRef("");
    const optionPopUp = useRef();

    const currentTextInsert = (e) => {
        setText((val) => {
            let sortingVal = val.filter((value) => value !== e);
            return [activeText, ...sortingVal];
        });
        setActiveText(e);
        setIsShowDropDown(false);
    };

    function onFunction(e) {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setViewGroup();
        }
    }

    const SearchUser = (e) => {
        if (e === "") {
            setAllViewGroup([]);
        } else {
            let all_view_group = data?.map((val) => {
                if (val.title.toLocaleLowerCase().includes(e.toLocaleLowerCase())) {
                    return val;
                }
                return null;
            })
                .filter(Boolean);

            setAllViewGroup(all_view_group);
        }
    };
    const active_input_field_fn = () => {
        let search_field = document.querySelector(".search_field");
        search_field.focus();
    }

    return (
        <div
            className={
                open
                    ? "h-[100vh] bg-[#ffffff54] fixed overflow-hidden top-0 right-0 left-0 z-10  w-full flex items-center justify-center view_all_group"
                    : "h-[100vh] bg-[#ffffff54] bg-opacity-70 fixed overflow-hidden top-0 right-0 left-0 z-10  w-full flex items-center justify-center view_all_group"
            }
            style={{
                opacity: open ? 1 : 0,
                zIndex: open ? 11111111111 : -1,
                visibility: open ? "unset" : "hidden",
            }}
            onClick={onFunction}
        >
            <div
                role="alert"
                className="center_div_view_all_group"
                ref={modalRef}
            >
                {/* top part */}

                <div className='top_part'>
                    <h1>Groups</h1>

                    <div className='cross_icon' onClick={() => setViewGroup()}>
                        <FontAwesomeIcon icon={faXmark} />
                    </div>
                </div>

                {/* search and filter section */}

                <div className='search_and_filter'>
                    <div className='left_side'>
                        <input type='text' placeholder='Search' className='search_field'
                            onChange={(e) => SearchUser(e.target.value)}
                        />
                        <div className='search_icon' onClick={active_input_field_fn}>
                            <img src={SearchIcon} alt="search" />
                        </div>
                    </div>

                    {/* drop down */}
                    <div className="Filter_dropDown" ref={optionPopUp}>
                        <div
                            className="labelText"
                            onClick={() => setIsShowDropDown(!isShowDropDown)}
                        >
                            <h4>{activeText}</h4>
                            <FontAwesomeIcon icon={faAngleDown} />
                        </div>

                        {/* option */}
                        <div
                            className="dropDown_option"
                            style={{ transform: isShowDropDown ? "scale(1)" : "scale(0)" }}
                        >
                            {text.map((val, index) => {
                                return (
                                    <p key={index} onClick={() => currentTextInsert(val)}>
                                        {val}
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className='viewAllGroup'>
                    {
                        allViewGroup?.length > 0 ? allViewGroup.map((value) => {
                            let { img, title, description, id } = value;
                            return (
                                <div className='group' key={id}>
                                    <div className='left_side'>
                                        <div className='sub_left_side'>
                                            <img src={img ?? vg_image1} alt="group" />

                                            <div className='responsive_right_side'>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </div>
                                        </div>
                                        <div className='sub_right_side'>
                                            <h1>{title ?? "---"}</h1>
                                            <p>{description ?? "---"}</p>
                                        </div>
                                    </div>
                                    <div className='right_side'>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </div>
                                </div>
                            )
                        }) :
                            data?.length > 0 && data.map((value) => {
                                if (activeText === "All" || value?.type === activeText) {
                                    let { img, title, description, id } = value;
                                    return (
                                        <div className='group' key={id}>
                                            <div className='left_side'>
                                                <div className='sub_left_side'>
                                                    <img src={img ?? vg_image1} alt="group" />

                                                    <div className='responsive_right_side'>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </div>
                                                </div>
                                                <div className='sub_right_side'>
                                                    <h1>{title ?? "---"}</h1>
                                                    <p>{description ?? "---"}</p>
                                                </div>
                                            </div>
                                            <div className='right_side'>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </div>
                                        </div>
                                    )
                                }
                                return null;
                            })
                    }
                </div>

            </div>

        </div>
    )
}

export default ViewAllGroup;