import React, { useState, useRef, useEffect } from "react";
import { FacebookShareCount, FacebookShareButton, HatenaShareCount, OKShareCount, PinterestShareCount, RedditShareCount, TumblrShareCount, VKShareCount } from "react-share";

// React Router
import { useNavigate } from "react-router-dom";

// Redux toolkit
import { useDispatch, useSelector } from "react-redux";
import { addComment } from "../../Store/PostsSlice";
import axios from "axios";
import StorageService from "../../services/StorageService";
import { get, post } from "../../api/api";
// emoji picker
import EmojiPicker from "emoji-picker-react";

// Models
import { BeforeLoginMessage } from "../Models/Index";

// Component
import CommentAdd from "./CommentAdd";

// Style
import "../../style/MochatPostSection.css";
import { toast } from "react-toastify";

// Icons
import { BsThreeDotsVertical } from "react-icons/bs";
import { DownArrow1Icon, TopArrowIcon, Notification2Icon, ChatIcon, ShareIcon, DeletePostIcon, DarkEmojiIcon, EditIcon, EditIconSvg } from "../../constant/IconsPath";
import { logo4 } from "../../constant/imagePath";
import { startLoading, stopLoading } from "../../Store/IdentitySlice";
import EditPostSection from "./EditPostSection";
import { updateTrendingPostData, updateTrendingPostDataOnSearch } from "../../Store/TrendingPageSlice";

const MochatPostSection = ({ data: data1, addMargin, sepratePage, updateState,url,disableThreeDot }) => {
  const [postData, setPostData] = useState({});
  const [homeView,setHomeView]=useState(false)
  const { postData: TopPostData } = useSelector((state) => state.Trending);
  let data;
  if (data1?.id === postData?.id) {
    data = postData;
  } else {
    data = data1;
  }
  const { id, userName: name, content, title, createdAt: date, convertedDate, profilePicture: logo, picture: postImage, imagePath } = data;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = StorageService.getJwt();
  const [flag, setFlag] = useState(false);
  const [pushAndDown, setPushAndDown] = useState(null);
  const [showEmoji, setShowEmoji] = useState(false);
  const inputFieldMessage = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [num, setNum] = useState(5);
  const [isEmpty, setIsEmpty] = useState(true);
  const [limitedCommentShow, setLimitedCommentShow] = useState([]);
  const [comments, setComment] = useState([]);
  const [messageForLogin, setMessageForLogin] = useState(false);
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [topic, setTopic] = useState(false);
  const [marked, setMarked] = useState(false);
  const [EditPost, setEditPost] = useState(false);
  const [EditPostData, setEditPostData] = useState({});
  const [Share, setShare] = useState(false);
  const [loading, setLoading] = useState(true);
  // let Username = StorageService?.getValue("userName")
  const PostOption = useRef("");
  const EmojiIcon = useRef("");
  let userId = StorageService?.getValue("userId")

  useEffect(() => {
    // getNewComentes()
    let token=StorageService?.getJwt();
    if(token){
      setHomeView(false)
    }else{
      setHomeView(true)
    }
    const handler = (e) => {
      if (PostOption.current && !PostOption.current.contains(e.target)) {
        setFlag(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const getNewComentes = async (val) => {
    try {
      // let res = await post(`/Comment/ReadPostComments/${val}`);
      let res =await post (`/Comment/ReadPostComments/${val}?userId=${userId}`);
      let data = await res.json();
      console.log("rescomment ", data);
      if (res && data) {
        setComment(data);
        // setTopPostData(data);
        // dispatch(updateTrendingPostData(data));
        // dispatch(stopLoading());
      } else {
        // dispatch(stopLoading());
      }
    } catch (error) {
      console.error("Error fetching top trending posts:", error);
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    const handler1 = (e) => {
      if (EmojiIcon.current && !EmojiIcon.current.contains(e.target)) {
        setShowEmoji(false);
      }
    };
    document.addEventListener("mousedown", handler1);
    return () => {
      document.removeEventListener("mousedown", handler1);
    };
  }, []);

  const shoeCommentHandel=(id)=>{
    // if (homeView) {
    //   setMessageForLogin(!messageForLogin);
    //   return
    // } 
    getNewComentes(id);
    setShowCommentSection(!showCommentSection);
  }

  const getDataById = async (postId) => {
    const token = StorageService.getJwt();
    try {
      setLoading(true);
      const res = await axios.post(
        `${StorageService?.baseURL}/Post/ReadPost/${postId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPostData(res.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching post data:", error);
      setLoading(false);
    }
  };
  
  const CommentAddFunction = async (id) => {
    if (homeView) {
      setMessageForLogin(!messageForLogin);
      return
    } 
    const token = StorageService.getJwt();
    
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let body = {
        content: inputValue,
      };
      let res = await axios.post(`${StorageService?.baseURL}/Comment/CreateComment/${id}`, body, header);
      if (res.status === 200) {
        setInputValue("");
        getNewComentes(id);
        // toast.success("Comment added successfully");
        // updateState();
        // dispatch(stopLoading());
        setFlag(false);
      } else {
        console.log(res, "result");
        toast.error(res);
        dispatch(stopLoading());
        setFlag(false);
      }
    } catch (error) {
      dispatch(stopLoading());
      setFlag(false);
      console.error("Error deleting post:", error);
    }
  };

  const setInputHeight = (element, defaultHeight) => {
    if (element) {
      if (element.target.value.trim().length !== 0) {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }

      setInputValue(element.target.value);

      const target = element.target ? element.target : element;
      target.style.height = defaultHeight;
      target.style.height = `${target.scrollHeight}px`;
    }
  };

  const setInputHeight1 = (element, defaultHeight) => {
    if (element) {
      setIsEmpty(false);

      setInputValue((prevValue) => `${prevValue} ${element.emoji}`);

      const typingArea = document.querySelector(".typingArea");
      typingArea.style.height = defaultHeight;
      typingArea.style.height = `${typingArea.scrollHeight}px`;
    }
  };

  const pageNavigateFunction = () => {
    let modifyTitle=title?.replaceAll(" ","-")
    navigate(`/media/${modifyTitle}`, {
      state: { id, title },
    });
  };

  const DeletePost = async (idd) => {
    const token = StorageService.getJwt();
    dispatch(startLoading());
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let res = await axios.post(`${StorageService?.baseURL}/Post/DeletePost/${idd}`, {}, header);
      if (res.status === 200) {
        toast.success(res.data);
        // updateState();
        let filterData = TopPostData?.filter((ele) => ele?.id !== idd);
        dispatch(updateTrendingPostDataOnSearch(filterData));
        dispatch(stopLoading());
        setFlag(false);
      } else {
        console.log(res, "result");
        toast.error(res);
        dispatch(updateTrendingPostDataOnSearch(TopPostData));
        dispatch(stopLoading());
        setFlag(false);
      }
    } catch (error) {
      dispatch(stopLoading());
      setFlag(false);
      console.error("Error deleting post:", error);
    }
  };

  const UpVote = async (PostId) => {
    // homeView ? setMessageForLogin(!messageForLogin) : setPushAndDown(true);
    if (homeView) {
      setMessageForLogin(!messageForLogin);
      return
    } 
    const token = StorageService.getJwt();
    console.log(token, "tokens");
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await post(`/Post/UpVote/${PostId}`, header);
      console.log(res, "res2");
      getDataById(PostId);
      // updateState();
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const DownVote = async (PostId) => {
    // homeView ? setMessageForLogin(!messageForLogin) : setPushAndDown(false);
    if (homeView) {
      setMessageForLogin(!messageForLogin);
      return
    }
    const token = StorageService.getJwt();
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await post(`/Post/DownVote/${PostId}`, header);
      console.log(res, "res2");
      // updateState();
      getDataById(PostId);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const addBookMark = async (PostId) => {
    if (homeView) {
      setMessageForLogin(!messageForLogin);
      return
    } 
    const token = StorageService.getJwt();
    dispatch(startLoading());
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await post(`/Post/AddBookmark?postId=${PostId}`, header);
      let data = await res.json();
      if (data?.post?.bookmark === true) {
        toast.success("Bookmark added successfully!");
      } else {
        toast.success("Bookmark updated successfully!");
      }
      getDataById(PostId);
      dispatch(stopLoading());
    } catch (error) {
      dispatch(stopLoading());
      console.error("Error adding bookmark:", error);
    }
  };

  const shareUrl = "https://mochatforum.com/media/"; // Your page URL

  return (
    <React.StrictMode>
      { <BeforeLoginMessage helpModal={messageForLogin} setHelpModal={setMessageForLogin} />}

      <div
        className={`shadow-[0_4px_32px_rgba(0,0,0,0.08)] mb-5 p-[30px] rounded-lg responsive_post_design ${homeView && "homeView"}`}
        style={{
          margin: addMargin ? "2.5rem 0" : "",
          backgroundColor: "#131313",
        }}
      >
        <div className="flex items-center justify-between top_responsive_post_design">
          <div className="flex items-center justify-center">
            <div
              
            >
              <img src={data?.readUserModel?.profilePicture ? `data:image/png;base64,${data?.readUserModel?.profilePicture}` : logo4} alt="Description of the image" style={{height:'50px',width:"50px",objectFit:"cover",borderRadius:"100px"}} />
            </div>

            <div className="ml-4">
              <h3 className="sm:text-xl font-bold mb-1 text-white">{name}</h3>
              <p className="text-[#999999] font-normal text-sm -mb-1">{data?.readUserModel?.userName ? data?.readUserModel?.userName : title}</p>
              <small className="font-normal text-xs" style={{ color: "rgba(255, 255, 255, 0.25)" }}>
                {/* {formattedDate ? formattedDate : "null"} */}
                {convertedDate ? convertedDate : "null"}
              </small>
            </div>
          </div>

          {/* ************************** EDIIIITTTTTTTTTTTT POSTTTTT*********************************************************** */}
          {EditPost && (
            <EditPostSection EditPost={EditPost} setEditPost={setEditPost} data={EditPostData} flag={flag} setFlag={setFlag} MainClassName={"image_upload"} open={topic} setTopic={setTopic} setPostData={setPostData}/>
          )}
          {/* <EditPostSection/> */}
 
            <div className="flex items-center justify-center -mr-[12px] sm:-mr-0">
              {data?.editFlag&&!disableThreeDot&&<div
                className="sm:ml-3 sm:w-[36px] sm:h-[36px] sm:border-solid sm:border-[0.9px] sm:border-[#000] sm:rounded-full flex items-center justify-center sm:bg-[#000] optionBtn"
                style={{ position: "relative", cursor: "pointer" }}
                ref={PostOption}
              >
                <BsThreeDotsVertical className="text-[#fff] p-1 text-3xl" onClick={() => setFlag(!flag)} />
                {flag && (
                  <div className="option">
                    {data.editFlag === true && (
                      <>
                        <div className="option1">
                          <DeletePostIcon />
                          <p onClick={() => DeletePost(id)}>Delete this post</p>
                        </div>
                        <div
                          className="option1"
                          onClick={() => {
                            setEditPostData(data);
                            setEditPost(!EditPost);
                          }}
                        >
                          <EditIconSvg />
                          <p>Edit Post</p>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>}
            </div>
         
        </div>
        <div className="py-4 description">
          <h3 className="title text-white" onClick={()=>  pageNavigateFunction()} style={{ cursor: sepratePage || homeView ? "auto" : "pointer" }}>
            {title || ""}
          </h3>
          <pre className="text-lg font-light text-[#636363] description" onClick={()=> pageNavigateFunction()} style={{ cursor: sepratePage || homeView ? "auto" : "pointer", color: "#b2b2b2", fontSize: "0.9rem", fontFamily: "sans-serif", fontWeight: 300,whiteSpace:"pre-wrap" }}>
            {content || ""}
          </pre>
        </div>    

        {imagePath &&imagePath?.length>40&& (
          <div className="image-container" style={{ height: "auto", width: "100%", overflow: "hidden" }}>
            <img src={url?"https://betaapi.mochatforum.com/"+imagePath:imagePath} alt="logo" className="image" style={{ height: "auto", width: "100%", display: "block", margin: "0px auto" }} />
          </div>
        )}
        
        <div className="flex items-center justify-between py-4 follow_bottom_design">
          <div className="flex items-center justify-center gap-4 sm:gap-6">
            <div className="flex items-center justify-center cursor-pointer" onClick={() => (data?.readVote?.upvoteFlag ? null : UpVote(id))}>
              <TopArrowIcon topArrow_color={!data?.readVote?.upvoteFlag ? "#999999" : "#ffa000"} />
              <p className="ml-1" style={{ marginLeft: "0.1rem" }}>
                <span
                  style={{
                    color: !data?.readVote?.upvoteFlag ? "#999999" : "#ffa000",
                  }}
                >
                  {data?.readVote?.upvoteCount ? data?.readVote?.upvoteCount : 0}
                </span>
              </p>
            </div>

            <div className="flex items-center justify-center cursor-pointer" onClick={() => (data?.readVote?.downvoteFlag ? null : DownVote(id))}>
              <DownArrow1Icon downArrow_color={!data?.readVote?.downvoteFlag ? "#999999" : "#ffa000"} />
              <p className="ml-1">
                <span
                  style={{
                    color: !data?.readVote?.downvoteFlag ? "#999999" : "#ffa000",
                  }}
                >
                  {data?.readVote?.downvoteCount ? data?.readVote?.downvoteCount : 0}
                </span>
              </p>
            </div>

            <img
              src={ChatIcon}
              alt="chat icon"
              className="text-[#999999] sm:text-xl cursor-pointer icon3"
              onClick={() => shoeCommentHandel(id)}
              height={"17px"}
              width={"20px"}
            />

            {Share && <FacebookShareCount url={`${shareUrl}${id}`}>{(shareCount) => <span className="myShareCountWrapper">{shareCount}</span>}</FacebookShareCount>}

            {Share && (
              <FacebookShareButton url={`${shareUrl}${id}`} quote="Check out this profile photo!">
                {/* <img
                  src={ShareIcon}
                  alt="share icon"
                  className="text-[#999999] sm:text-xl cursor-pointer icon4"
                  height={"17px"}
                  width={"20px"}
                /> */}
                <button
                  type="button"
                  data-twe-ripple-init
                  data-twe-ripple-color="light"
                  class="mb-2 inline-block rounded bg-[#1877f2] px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                >
                  <span class="[&>svg]:h-4 [&>svg]:w-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 320 512" style={{ width: "10px", height: "10px" }}>
                      <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                    </svg>
                  </span>
                </button>
              </FacebookShareButton>
            )}

            <img src={ShareIcon} alt="share icon" className="text-[#999999] sm:text-xl cursor-pointer icon4" onClick={() => !homeView ? setShare(!Share):setMessageForLogin(!messageForLogin)} height={"17px"} width={"20px"} />
      
            {/* (homeView ? setMessageForLogin(!messageForLogin) : setMarked(!marked))} */}
            <div className="text-[#999999] sm:text-xl cursor-pointer icon5" onClick={() => addBookMark(id)}>
              <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m15.705 18.864-7-4.995-7 4.994V2.88a1.997 1.997 0 0 1 2-2h10a2 2 0 0 1 2 1.999v15.984z"
                  stroke={marked ? "#ffa000" : "#939393"}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* hr line */}
        <hr className="text-[#707070] opacity-10" />

        {showCommentSection && (
          <React.StrictMode>
            {/* {Mochat follow reply section} */}
            <div className="MainReply">
              {/* left side */}
              <div className="MainReply_leftSide">
                <img src={logo4} alt="user icon" height={"auto"} width={"auto"} />
              </div>

              {/* right side */}
              <div className="MainReply_rightSide">
                <textarea
                  type="text"
                  placeholder="Add a reply..."
                  className={`typingArea comment_typing_area${id}`}
                  value={inputValue}
                  onChange={(event) => {
                    setInputHeight(event, "35px");
                  }}
                  ref={inputFieldMessage}
                />

                <div className="MainReplyBottomPart">
                  {/* emoji */}
                  <div className="emojiIcon" ref={EmojiIcon}>
                    {/* emoji icon */}
                    <img src={DarkEmojiIcon} alt="Emoji Icon" onClick={() => setShowEmoji((val) => !val)} height={"auto"} width={"auto"} />

                    {/* emoji */}
                    {showEmoji && (
                      <EmojiPicker
                        onEmojiClick={(event) => {
                          setInputHeight1(event, "35px");
                        }}
                        autoFocusSearch={false}
                        searchDisabled
                        width="100%"
                        lazyLoadEmojis={true}
                      />
                    )}
                  </div>

                  {/* buttons */}
                  <div className="funtionButton">
                    <button
                      className="active"
                      onClick={() => CommentAddFunction(id)}
                      // onclick={()=>handleAddComment(id)}
                      disabled={isEmpty === true ? true : false}
                      style={{
                        opacity: isEmpty === true ? 0.6 : 1,
                        cursor: isEmpty === true ? "not-allowed" : "pointer",
                      }}
                    >
                      Comment
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="comment_section">
              {comments &&
                comments.length > 0 &&
                comments.map((value, index) => {
                  return (
                    <React.StrictMode key={index}>
                      <CommentAdd content={value} comments={comments} limitedCommentShow={comments} sepratePage={true} parentId={id} updateState={updateState} getNewComentes={getNewComentes} homeView={homeView} setMessageForLogin={setMessageForLogin} messageForLogin={messageForLogin}/>
                    </React.StrictMode>
                  );
                })}
            </div>
          </React.StrictMode>
        )}
      </div>
    </React.StrictMode>
  );
};

export default MochatPostSection;
