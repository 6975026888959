import React, { useEffect, useState } from 'react';

// React router
import { useLocation, useNavigate } from 'react-router-dom'

// Redux Toolkit
import { useDispatch, useSelector } from 'react-redux';

// style
import "../../style/MediaContentPage.css";

// component
import MochatPostSection from '../../components/post/MochatPostSection';

// awesome icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import StorageService from '../../services/StorageService';
import { startLoading, stopLoading } from '../../Store/IdentitySlice';

const MediaContentPage = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const {isLoading} = useSelector((state)=>state?.identity)
    const [postData, setPostData] = useState([]);
    useEffect(() => {
        if (location.state.id) {
            getDataById(location.state.id);
        }
    }, [location.state.id]);
    const getDataById = async (postId) => {
        dispatch(startLoading())
        const token = StorageService.getJwt();
        try {
            const res = await axios.post(
                `${StorageService?.baseURL}/Post/ReadPost/${postId}?userId=${StorageService?.getValue("userId")?StorageService?.getValue("userId"):null}`, {}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
            );
            if(res.data){
                setPostData(res.data);
                dispatch(stopLoading())
            }else{
                dispatch(stopLoading())
            }
                
        } catch (error) {
            console.error('Error fetching post data:', error);
            dispatch(stopLoading())
        }
    };
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <section className='media_content_page container mx-auto' style={{
            display: "flex", justifyContent: "center",
            alignItems: "flex-start"
        }}>
            <div className='go_back' onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </div>
           {!isLoading&& <MochatPostSection data={postData} sepratePage={true} />}
        </section>
    )
}

export default MediaContentPage





