import React, { useEffect, useRef, useState,dispatch } from "react";

// awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import StorageService from "../../services/StorageService";
import { useDispatch } from "react-redux";
import axios from "axios";
import { startLoading, stopLoading } from "../../Store/IdentitySlice";


const AccountInfo = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState({
    recoveryEmail: "user@example.com",
    phoneNo: "+xxxxxxxxxx",
  });
  const [value1, setValue1] = useState({ recoveryEmail: "", phoneNo: "" });
  const [readOnly, setReadOnly] = useState(true);
  const [readOnly1, setReadOnly1] = useState(true);
  const [userName, setUsername] = useState("");
  const [email, setemail] = useState(null);
  const [profile, setProfile] = useState(null);
  let userId = StorageService?.getValue("userId")

  const inputField = useRef("");
  const phoneNumber = useRef("");


  useEffect(() => {
    // useEffect logic...
    getUserData()
  }, []);

  const getUserData = async () => {
    try {
      dispatch(startLoading());
      const token = StorageService.getJwt();
      const response = await axios.post(
        `${StorageService?.baseURL}/Identity/GetUser?userId=${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        const userData = response?.data?.user;
        console.log("userData", userData);
        setUsername(userData?.userName);
        setemail(userData?.email);
        // setProfile(userData?.profilePicture); 
        // setCoverPic(userData?.coverPicture);
        // StorageService.setValue("userName", userData?.userName);
        StorageService?.setJwt(response?.data?.token);
        // StorageService.setValueProfile("profilePic", userData?.profilePicture);
        dispatch(stopLoading());
      }
    } catch (error) {
      dispatch(stopLoading());
      console.error("Error fetching user data:", error);
    }
  };

  const able = () => {
    setReadOnly(false);
    inputField.current.focus();
  };

  const able1 = () => {
    setReadOnly1(false);
    phoneNumber.current.focus();
  };

  const enable = () => {
    setReadOnly(true);
    setValue(value1);
  };

  const enable1 = () => {
    setReadOnly1(true);
    setValue(value1);
  };

  const defaultValue = () => {
    setReadOnly(true);
  };

  const defaultValue1 = () => {
    setReadOnly1(true);
  };

  return (
    <div className="sm:mb-6" style={{ border: "1px solid rgba(138, 152, 164, 0.47)" }}>
      <div className="bg-[#c8ced436] px-[30px] py-5 loginSessionHeading">
        <h3 className="text-[20px] font-bold text-[#fff]">Account Info</h3>
      </div>

      <div className="px-[30px] AccountInfoCenterStyle bg-[#131313]">
        <div className="py-4">
          <h3 className="text-[20px] font-bold mb-1 text-[#fff]">Full Name</h3>
          <span className="text-[20px] text-[#868B90]">{userName}</span>
        </div>

        <div className="py-5" style={{ borderTop: "1px solid rgba(138, 152, 164, 0.4)", borderBottom: "1px solid rgba(138, 152, 164, 0.4)" }}>
          <h3 className="text-[20px] font-bold mb-1 text-[#fff]">User Name</h3>
          <span className="text-[20px] text-[#868B90]">{userName}</span>
        </div>

        <div className="py-5">
          <h3 className="text-[20px] font-bold mb-1 text-[#fff]">Login Email</h3>
          <span className="text-[20px] text-[#868B90]">{email}</span>
        </div>

        <div className="py-5 flex items-center justify-between" style={{ borderTop: "1px solid rgba(138, 152, 164, 0.4)", borderBottom: "1px solid rgba(138, 152, 164, 0.4)" }}>
          <div className="left-side" style={{ width: "80%" }}>
            <h3 className="text-[20px] font-bold mb-1 text-[#fff]">Recovery Email</h3>

            <input
              ref={inputField}
              placeholder="user@example.com"
              type="email"
              value={readOnly ? value.recoveryEmail : value1.recoveryEmail}
              onChange={(val) =>
                readOnly
                  ? setValue({ ...value, recoveryEmail: val.target.value })
                  : setValue1({ ...value1, recoveryEmail: val.target.value })
              }
              className="text-[20px] text-[#868B90] bg-[transparent]"
              style={{ outline: "none", width: "100%" }}
              readOnly={readOnly}
            />
          </div>
          <div
            className="right-side"
            style={{ width: "62px", textAlign: "right" }}
          >
            {readOnly ? (
              <p
                className="text-[20px] text-[#868B90]"
                onClick={able}
                style={{ cursor: "pointer" }}
              >
                <span className="text-[#FEA500]">
                  {value1.recoveryEmail !== "" ? "Change Email" : "Add Email"}
                </span>
              </p>
            ) : (
              <div className="correct-wrong" style={{ display: "flex" }}>
                <FontAwesomeIcon
                  icon={faCheck}
                  onClick={enable}
                  style={{
                    padding: "0 0.5rem",
                    color: "#848b90",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                />
                <FontAwesomeIcon
                  icon={faXmark}
                  onClick={defaultValue}
                  style={{
                    padding: "0 0.5rem",
                    color: "#848b90",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="py-5 flex items-center justify-between">
          <div className="left-side" style={{ width: "80%" }}>
            <h3 className="text-[20px] font-bold mb-1 text-[#fff]">Phone Number</h3>

            <input
              ref={phoneNumber}
              placeholder="+xxxxxxxxxx"
              type="tel"
              value={readOnly1 ? value.phoneNo : value1.phoneNo}
              onChange={(val) =>
                readOnly1
                  ? setValue({ ...value, phoneNo: val.target.value })
                  : setValue1({ ...value1, phoneNo: val.target.value })
              }
              className="text-[20px] text-[#868B90] bg-[transparent]"
              style={{ outline: "none", width: "100%" }}
              readOnly={readOnly1}
            />
          </div>
          <div className="right-side">
            {readOnly1 ? (
              <p
                className="text-[20px] text-[#868B90]"
                onClick={able1}
                style={{ cursor: "pointer" }}
              >
                <span className="text-[#FEA500]">
                  {value1.phoneNo !== "" ? "Change" : "Add"}
                </span>
              </p>
            ) : (
              <div className="correct-wrong" style={{ display: "flex" }}>
                <FontAwesomeIcon
                  icon={faCheck}
                  onClick={enable1}
                  style={{
                    padding: "0 0.5rem",
                    color: "#848b90",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                />
                <FontAwesomeIcon
                  icon={faXmark}
                  onClick={defaultValue1}
                  style={{
                    padding: "0 0.5rem",
                    color: "#848b90",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
