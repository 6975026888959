import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import StorageService from "../services/StorageService";

// Image
import { warningImg } from "../constant/imagePath";

// Components
import AccountActivation from "../components/AccountActivation/AccountActivation";
import { Header } from "../components";

const AccountNotActivatedPage = () => {



  const handleResendEmail = async () => {
    console.log("Resend Api call")
    try {
      // Call your API here for resending email
      const response = await axios.post(`${StorageService?.baseURL}/your-resend-email-api-endpoint`);
      console.log("Response:", response);

      // Show success message if API call succeeds
      toast.success("Email resent successfully.");
    } catch (error) {
      // Show error message if API call fails
      console.error("Error:", error);
      toast.error("Failed to resend email. Please try again.");
    }
  };

  let heading = "Account Not Activated";
  let content = (
    <p style={{ padding: "1.5rem 0" }}>
      We have sent an activation link to{" "}
      <span style={{ fontWeight: "bold" }}>user@gmail.com</span> to verify your
      email address and activate your account. The link in the email will expire
      in 24 hours. Make sure to check your spam folder.
    </p>
  );
  let btnConfig = [
    { id: 1, name: "Resend Email", to: "/login", noBg: true, onClick: handleResendEmail },
    { id: 2, name: "Login to another account", to: "/login" },
  ];

  return (
    <React.StrictMode>
      {/* Header */}
      <Header loginAndSignup={true} messageShow={false} />

      <AccountActivation
        heading={heading}
        content={content}
        btnConfig={btnConfig}
        img={warningImg}
        boxWidth={"695px"}
        Margin="0rem auto"
      />
    </React.StrictMode>
  );
};

export default AccountNotActivatedPage;
