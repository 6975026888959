import React, { useState, useRef, useEffect } from "react";

// React toolkit
import { useDispatch } from "react-redux";
import { deleteComment, replyAdd } from "../../Store/PostsSlice";

// components
import NestedComment from "./NestedComment";
import EditComment from "./EditComment";

// emoji picker
import EmojiPicker from "emoji-picker-react";

// Icons
import {
  DarkEmojiIcon,
  DownArrow1Icon,
  TopArrowIcon,
} from "../../constant/IconsPath";
import { logo4, profile } from "../../constant/imagePath";

// font awesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import StorageService from "../../services/StorageService";
import axios from "axios";
import { toast } from "react-toastify";
import { get, post } from "../../api/api";
import { startLoading, stopLoading } from "../../Store/IdentitySlice";

const CommentAdd = ({
  content,
  limitedCommentShow,
  comments,
  sepratePage,
  updateState,
  getNewComentes,
  homeView,
  setMessageForLogin,
  messageForLogin
}) => {
  const { userName: name, time, id, reply, parentId, content: comment, createdAt, convertedDate, postId, parentCommentId, commentReplies, readVote } = content || "";

  const dispatch = useDispatch();

  const [pushAndDown, setPushAndDown] = useState(null);
  const [nestedShowEmoji, setNestedShowEmoji] = useState(false);
  const [showCommentTextArea, setShowCommentTextArea] = useState(false);
  const [nestedInputValue, setNestedInputValue] = useState("");
  const [NestedlimitedCommentShow, setNestedLimitedCommentShow] = useState([]);
  const nestedInputFieldMessage = useRef("");
  const [num, setNum] = useState(1);
  const [photoURL, setPhotoURL] = useState(null);
  const EmojiIcon1 = useRef("");
  const [isEmpty1, setIsEmpty1] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  let Username = StorageService?.getValue("userName")
  const [showCommentsReply, setShowCommentsReply] = useState(false);
  const someProfile = StorageService.getValueProfilePic('profilePic');
  const secondSrc = someProfile != "null" ? `data:image/jpeg;base64,${someProfile}` : null;
  const firstSrc = photoURL ? (photoURL.length > 100 ? `data:image/jpeg;base64,${photoURL}` : photoURL) : secondSrc || logo4;
  const [responsiveCommentFunction, setResponsiveCommentFunction] =
    useState(false);
  const [responsiveComment, setResponsiveComment] =
    useState(false);
  const [insideMenuList, setInsideMenuList] = useState(false);
  const [responsiveCommentID, setResponsiveCommentId] =
    useState(-1);

  const responsiveCommentFn = useRef();
  const insideMenuListRef = useRef();
  insideMenuListRef.current = insideMenuList


  useEffect(() => {
    const handler123 = (e) => {
      if (
        responsiveCommentFn.current && !insideMenuListRef.current &&
        !responsiveCommentFn.current.contains(e.target)
      ) {
        setResponsiveCommentFunction(false);
        setResponsiveComment(false);
        setResponsiveCommentId(-1)
      }
    };
    document.addEventListener("mousedown", handler123);
    return () => {
      document.removeEventListener("mousedown", handler123);
    };
  }, []);

  const NestingSetInputHeight = (element, defaultHeight) => {
    if (element) {
      if (element.target.value.trim().length !== 0) {
        setIsEmpty1(false);
      } else {
        setIsEmpty1(true);
      }
      setNestedInputValue(element.target.value);
      const target = element.target ? element.target : element;
      target.style.height = defaultHeight;
      target.style.height = `${target.scrollHeight}px`;
    }
  };

  const NestedSetInputHeight1 = (element, defaultHeight) => {
    if (element) {
      setIsEmpty1(false);
      setNestedInputValue((prevValue) => `${prevValue} ${element.emoji}`);
      const typingArea1 = document.querySelector(".typingArea1");
      typingArea1.style.height = defaultHeight;
      typingArea1.style.height = `${typingArea1.scrollHeight}px`;
    }
  };

  const NestedCancelReplyFunction = () => {
    setNestedInputValue("");
    setShowCommentTextArea(false);
    setIsEmpty1(true);
  };

  const NestedReplyAddFunction = () => {
    let random = Math.random();

    const reply1 = {
      parentId: parentId,
      commentId: id,
      reply: {
        id: random,
        parentId: parentId,
        commentId: id,
        name: "Sally",
        time: "1 min ago",
        reply: nestedInputValue,
        replyPush: 0,
        replyDown: 0,
      }
    }

    dispatch(replyAdd(reply1));
    setShowCommentTextArea(false);
    setNestedInputValue("");
    setNestedShowEmoji("");
    setIsEmpty1(true);
  };

  useEffect(() => {
    if (reply?.length > 0) {
      setNestedLimitedCommentShow([...reply.slice(0, 1 * num)]);
    } else {
      setNestedLimitedCommentShow([]);
    }
  }, [content, num, reply]);

  const showMoreComments = () => {
    setNum(num + 1);
  };

  useEffect(() => {
    if (reply?.length > 0) {
      setNestedLimitedCommentShow(reply.slice(0, 1 * num));
    }
  }, [num, reply]);

  const replyFunction = () => {
    if (homeView) {
      setMessageForLogin(!messageForLogin);
      return;
    }

    setShowCommentTextArea(true);
    const typingArea1 = document.querySelector(".typingArea1");
    if (typingArea1) {
      typingArea1.focus();
    }
  };
  const sendReplyFunction = async (postId, commentId) => {
    NestedReplyAddFunction();
    const typingArea1 = document.querySelector(".typingArea1");
    if (typingArea1) {
      typingArea1.focus();
    }
    const token = StorageService.getJwt();
    // dispatch(startLoading());
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let body = {
        content: nestedInputValue,
        // profilePicture: localStorage.getItem("profilePic") || null,
      };
      let res = await post(`/Comment/CreateReplyComment/${postId}/${commentId}`, body, header);
      // updateState();
      getNewComentes(postId)
      // setShowReplies(true);
      // toast.success("Reply send successfully!");
      // dispatch(stopLoading());
    }
    catch (error) {
      // dispatch(stopLoading());
      console.error("Error sending reply:", error);
    }
  };

  const editCommentFn = () => {
    setResponsiveCommentFunction(false);
    setIsEdit(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };

  const formattedDate = formatDate(createdAt);

  const delete_comment = async (id, postId, e) => {
    e.preventDefault()

    const token = StorageService.getJwt();
    let header = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }

    }

    let res = await axios.post(`${StorageService?.baseURL}/Comment/DeleteComment/${id}`, {}, header)

    if (res.status == 200) {
      toast.success("Delete comment successfully")
      getNewComentes(postId)
    } else {
      toast.error(res?.data?.message)
    }
  }

  const UpdateComment = () => {
    setIsEdit(false);
  };

  const UpVote = async (CommentId, postId) => {
    if (homeView) {
      setMessageForLogin(!messageForLogin)
      return
    }
    setPushAndDown(true);
    const token = StorageService.getJwt();
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await post(`/Comment/UpVote/${CommentId}`, header);
      if (res) {
        getNewComentes(postId)
      }
    }
    catch (error) {
      console.error("Error deleting post:", error);
    }
  }

  const DownVote = async (CommentId, postId) => {
    if (homeView) {
      setMessageForLogin(!messageForLogin)
      return
    }
    const token = StorageService.getJwt();
    setPushAndDown(false);
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await post(`/Comment/DownVote/${CommentId}`, header);
      if (res) {
        getNewComentes(postId)
      }
    }
    catch (error) {
      console.error("Error deleting post:", error);
    }
  }

  return (
    <React.StrictMode>
      <div className="comments_1">
        {isEdit ? (
          <EditComment
            comment={comment}
            UpdateComment={UpdateComment}
            setIsEdit={setIsEdit}
            commentId={id}
            postId={postId}
            parentId={parentId}
            getNewComentes={getNewComentes}
          />
        ) : (
          <div className="mobileResponsive">
            {/* <h3 className="text-lg font-md text-[#fff]">
              {content?.readUserModel?.userName ?? "Michael Bruno"}
            </h3> */}
            <div className="flex items-center justify-center">
              <div className="">
                {/* image */}
                <img src={content?.readUserModel?.profilePicture ? `data:image/png;base64,${content?.readUserModel?.profilePicture}` : logo4} alt="user" style={{ height: '50px', width: "50px", objectFit: "cover", borderRadius: "100px" }} />
              </div>
            </div>
            <div className="mobileResponsive_right_side">
              <div className="top_area">
                <div className="flex items-center justify-start responsive_comment_design">
                  <h3 className="text-lg font-md text-[#fff]">
                    {content?.readUserModel?.userName ?? "Michael Bruno"}
                  </h3>
                  <p className="text-xs ml-3 text-[#999999]">
                    {convertedDate ?? "8 min ago"}
                  </p>
                </div>
                <div className="top_area_right_side" ref={responsiveCommentFn}>
                  {content?.canEdit && <FontAwesomeIcon
                    icon={faEllipsis}
                    className="horizontalThreeDotsIcon"
                    onClick={() =>
                      setResponsiveCommentFunction(!responsiveCommentFunction)
                    }
                  />}
                  {responsiveCommentFunction && (
                    <div className="commentFunctionality">
                      {/* edit comment */}
                      <div className="editComment" onClick={editCommentFn}>
                        <FontAwesomeIcon icon={faPen} color="#fea500" />
                        <p>Edit Comment</p>
                      </div>

                      {/* Delete comment */}
                      <div className="deleteComment" onClick={(e) => delete_comment(id, postId,e)}>
                        <FontAwesomeIcon icon={faTrash} color="#fea500" />
                        <p>Delete Comment</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="comment_area">
                <p className="text-[#868B90] font-light text-base mb-2 user_comment_design">
                  {comment ?? "---"}
                </p>
              </div>

              <div className="bottomArea">
                <div className="bottomArea_left_side">
                  <div
                    className="flex items-center justify-center push_arrow cursor-pointer upWardStyle"
                    onClick={() => content?.readVote?.upvoteFlag ? null : UpVote(id, postId)}
                  >
                    <TopArrowIcon topArrow_color={!content?.readVote?.upvoteFlag ? "#999999" : "#ffa000"} width="20" height="20" />
                    <p
                      className="text-[17px]"
                      style={{ marginLeft: "0.1rem" }}
                    >
                      <span style={{ color: !content?.readVote?.upvoteFlag ? "#999999" : "#ffa000" }}>
                        {content?.readVote?.upvoteCount ? content?.readVote?.upvoteCount : 0}
                      </span>
                    </p>
                  </div>

                  <div
                    className="flex items-center justify-center ml-2 down_arrow cursor-pointer downWardStyle"
                    // onClick={() => setPushAndDown(false)}
                    onClick={() => content?.readVote?.downvoteFlag ? null : DownVote(id, postId)}
                  >
                    <DownArrow1Icon downArrow_color={!content?.readVote?.downvoteFlag ? "#999999" : "#ffa000"} width="15" height="15.78" />
                    <p
                      className="ml-1"
                      style={{ fontSize: "17px" }}
                    >
                      <span style={{ color: !content?.readVote?.downvoteFlag ? "#999999" : "#ffa000" }}>
                        {/* {pushAndDown === null ? 0 : pushAndDown ? 0 : 1} */}
                        {content?.readVote?.downvoteCount ? content?.readVote?.downvoteCount : 0}
                      </span>
                    </p>
                  </div>
                </div>
                {Username !== content?.readUserModel?.userName && (
                  <div className="bottomArea_right_side">
                    <button
                      className="text-primary text-base user_comment_replay_design"
                      onClick={replyFunction}
                      style={{ cursor: "pointer", border: "none", background: "none" }}
                    >
                      Reply
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {showCommentTextArea && (
          <div className="reply">
            {/* left side */}
            <div className="reply_leftSide">
              <img src={logo4} alt="user icon" width={"auto"} height={"auto"} />
            </div>

            {/* right side */}
            <div className="reply_rightSide">
              <textarea
                type="text"
                placeholder="Add a reply..."
                className="typingArea1"
                value={nestedInputValue}
                onChange={(event) => {
                  NestingSetInputHeight(event, "35px");
                }}
                ref={nestedInputFieldMessage}
              />

              <div className="replyBottomPart">
                {/* emoji */}
                <div className="emojiIcon" ref={EmojiIcon1}>
                  {/* emoji icon */}
                  <img
                    src={DarkEmojiIcon}
                    alt="Emoji Icon"
                    onClick={() => setNestedShowEmoji((val) => !val)}
                    width={"auto"}
                    height={"auto"}
                  />

                  {/* emoji */}
                  {nestedShowEmoji && (
                    <EmojiPicker
                      onEmojiClick={(event) => {
                        NestedSetInputHeight1(event, "35px");
                      }}
                      autoFocusSearch={false}
                      searchDisabled
                      width="100%"
                      lazyLoadEmojis={true}
                    />
                  )}
                </div>


                {/* buttons */}
                <div className="funtionButton">
                  <button onClick={NestedCancelReplyFunction}>Cancel</button>
                  <button
                    className="active1"
                    // onClick={isEmpty1 === true ? null : NestedReplyAddFunction}
                    onClick={() => sendReplyFunction(postId, id)}
                    disabled={isEmpty1 === true ? true : false}
                    style={{
                      opacity: isEmpty1 === true ? 0.6 : 1,
                      cursor: isEmpty1 === true ? "not-allowed" : "pointer",
                    }}
                  >
                    Reply
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="comment_section">
          {limitedCommentShow?.length > 0 &&
            NestedlimitedCommentShow?.length > 0 &&
            NestedlimitedCommentShow.map((value, index) => {
              return (
                <React.StrictMode key={index}>
                  <NestedComment
                    content={value}
                    parentId={parentId}
                    commentId={id}
                  />
                </React.StrictMode>
              );
            })}
        </div>

        <div className="mobileResponsiveComments">
          {content?.commentReplies && content?.commentReplies?.map((commentsData, ind) => {
            const { id, postId, convertedDate } = commentsData;
            return (
              <div className="comment-reply" >
                <div className="flex items-center justify-center">
                  <div className="">
                    <img src={commentsData?.readUserModel?.profilePicture ? `data:image/png;base64,${commentsData?.readUserModel?.profilePicture}` : logo4} alt="user" style={{ height: '50px', width: "50px", objectFit: "cover", borderRadius: "100px" }} />
                  </div>
                </div>
                <div className="mobileResponsive_right_side">
                  <div className="top_area">
                    <div className="flex items-center justify-start responsive_comment_design">
                      <h3 className="text-lg font-md text-[#fff]">
                        {commentsData?.readUserModel?.userName ?? "Michael Bruno"}
                      </h3>
                      <p className="text-xs ml-3 text-[#999999]">
                        {convertedDate ?? "8 min ago"}
                      </p>
                    </div>
                  </div>


                  <div className="comment_area">
                    <p className="text-[#868B90] font-light text-base mb-2 user_comment_design">
                      {commentsData?.content ?? "---"}
                    </p>
                  </div>


                  <div className="bottomArea">
                    <div className="bottomArea_left_side">
                      <div
                        className="flex items-center justify-center push_arrow cursor-pointer upWardStyle"
                        onClick={() => commentsData?.readVote?.upvoteFlag ? null : UpVote(commentsData.id, commentsData.postId)}
                      >
                        <TopArrowIcon topArrow_color={!commentsData?.readVote?.upvoteFlag ? "#999999" : "#ffa000"} width="20" height="20" />
                        <p
                          className="text-[17px]"
                          style={{ marginLeft: "0.1rem" }}
                        >
                          <span style={{ color: !commentsData?.readVote?.upvoteFlag ? "#999999" : "#ffa000" }}>
                            {commentsData?.readVote?.upvoteCount ? commentsData?.readVote?.upvoteCount : 0}
                          </span>
                        </p>
                      </div>

                      <div
                        className="flex items-center justify-center ml-2 down_arrow cursor-pointer downWardStyle"
                        // onClick={() => setPushAndDown(false)}
                        onClick={() => commentsData?.readVote?.downvoteFlag ? null : DownVote(commentsData.id, commentsData.postId)}
                      >
                        <DownArrow1Icon downArrow_color={!commentsData?.readVote?.downvoteFlag ? "#999999" : "#ffa000"} width="15" height="15.78" />
                        <p
                          className="ml-1"
                          style={{ fontSize: "17px" }}
                        >
                          <span style={{ color: !commentsData?.readVote?.downvoteFlag ? "#999999" : "#ffa000" }}>
                            {commentsData?.readVote?.downvoteCount ? commentsData?.readVote?.downvoteCount : 0}
                          </span>
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="top_area_right_side" ref={responsiveCommentFn}>
                  {(commentsData?.canEdit || commentsData?.readUserModel?.userName == Username) && <FontAwesomeIcon
                    icon={faEllipsis}
                    className="horizontalThreeDotsIcon"
                    onClick={() => {
                      setResponsiveCommentId(ind)
                      setResponsiveComment(!responsiveComment)
                    }
                    }
                  />}
                  {responsiveComment && responsiveCommentID == ind && (
                    <div className="commentFunctionality" onMouseEnter={() => setInsideMenuList(true)} onMouseLeave={() => setInsideMenuList(false)}>
                      <div className="deleteComment" onClick={(e) => delete_comment(id, postId, e)}>
                        <FontAwesomeIcon icon={faTrash} color="#fea500" />
                        <p>Delete Comments</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>

        {/* show more commments */}
        <div className="moreComments">
          {reply &&
            NestedlimitedCommentShow?.length >= 0 ? (
            NestedlimitedCommentShow.length === reply.length ? (
              ""
            ) :
              reply?.length >= 1 &&
                limitedCommentShow &&
                comments?.length === 1 ? (
                <p onClick={showMoreComments}>View more replies</p>
              ) : (
                limitedCommentShow?.length > 1 && (
                  <p onClick={showMoreComments}>View more replies</p>
                )
              )
          ) : (
            ""
          )}
        </div>
      </div>
    </React.StrictMode>
  );
};

export default CommentAdd;