import React, { useEffect, useRef, useState } from "react";

// React Router
import { Link, Outlet, useNavigate } from "react-router-dom";

// images
import {
  movie1,
  movie2,
  movie3,
  movie4,
  profile2,
} from "../constant/imagePath";
import { companyLogo4 } from "../constant/LogoPath";
import {
  CameraIcon,
  EditIcon,
  LeftArrowIcon,
} from "../constant/IconsPath";

// react icons
import { AiOutlinePlus } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";

// components
import { Header } from "../components";
import GroupMembers from "../components/Models/GroupMembers";
import GroupCreate from "../components/Models/GroupCreate";
import InviteMembers from "../components/Models/InviteMembers";
import CropEasy from "../components/Crop/CropEasy";
import { Help, Message, PostCreate, FileUploaded, FileNotUploaded } from "../components/Models/Index";
import LeftSideProfileSection from "./userProfileView/LeftSideProfileSection";
import ViewAllGroup from "../components/Models/ViewAllGroup/ViewAllGroup";

// style
import classes from "../pages/Settings/EditProfile.module.css";
import Footer from "../components/Footer/Footer";

const AdminCommunityGroup = () => {
  const [showCreate, setShowCreate] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [topic, setTopic] = useState(false);
  const [photoURL, setPhotoURL] = useState();
  const [openCrop, setOpenCrop] = useState(false);
  const [flag, setFlag] = useState(true);
  const [helpModal, setHelpModal] = useState(false);
  const [showMember, setShowMember] = useState(false);
  const [viewGroup, setViewGroup] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [image, setImages] = useState([]);
  const [flag2, setFlag2] = useState(false);
  const [flag1, setFlag1] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [notUploaded, setNotUploaded] = useState(false);

  const navigate = useNavigate();

  const CHcover = useRef();

  useEffect(() => {
    const handler1 = (e) => {
      if (CHcover.current && !CHcover.current.contains(e.target)) {
        setFlag1(false);
      }
    };
    document.addEventListener("mousedown", handler1);
    return () => {
      document.removeEventListener("mousedown", handler1);
    };
  }, []);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setOpenCrop(true);
      setPhotoURL(URL.createObjectURL(file));
    } else {
      setOpenCrop(false);
      setPhotoURL();
    }
  };

  const fileChange = (e) => {
    setFlag1(false);
    const file = e.target.files;
    if (file.length === 0) return;
    const files = e.target.files[0];
    switch (files.type) {
      case "image/png":
        setUploaded(true);
        break;
      case "image/jpg":
        setUploaded(true);
        break;
      case "image/jpeg":
        setUploaded(true);
        break;
      default:
        setNotUploaded(true);
    }

    for (let i = 0; i < file.length; i++) {
      if (file[i].type.split("/")[0] !== "image") {
        alert("Online image support");
      } else {
        if (!image.some((e) => e.name === file[i].name)) {
          setImages(() => [
            {
              name: file[i].name,
              url: URL.createObjectURL(file[i]),
            },
          ]);
        }

        setFlag2(true);
      }
    }
  };

  const changeCoverFunction = () => {
    setFlag2(false);
  };

  const resetCoverFunction = () => {
    setFlag1(false);
    setImages([]);
    setFlag2(false);
  };

  return (
    <React.StrictMode>
      <Header loginAndSignup={false} messageShow={true} />
      <div className="container mx-auto containerContent homePageStyle">
        {/* Models  */}

        <GroupCreate open={editGroup} setShowCreate={setEditGroup} pop_up_title={"Edit Group"} titleShow={false} userNameShow={false} description_tile={"Write something about your group.."} button_title={"Save Changes"} height_of_textarea={"160px"} />


        <ViewAllGroup open={viewGroup} setViewGroup={setViewGroup} />

        <GroupMembers
          open={showMember}
          toggle={() => setShowMember((prev) => !prev)}
        />

        <InviteMembers
          open={showInvite}
          toggle={() => setShowInvite((prev) => !prev)}
        />
        <GroupCreate open={showCreate} setShowCreate={setShowCreate} titleShow={true} userNameShow={true} />

        <PostCreate
          open={topic}
          setTopic={setTopic}
          flag={flag}
          setFlag={setFlag}
          MainClassName={"image_upload"}
        />

        <Help helpModal={helpModal} setHelpModal={setHelpModal} />

        {openCrop && (
          <CropEasy
            {...{ photoURL, setOpenCrop, setPhotoURL }}
            profile={profile2}
          />
        )}

        <FileNotUploaded
          toggle={() => setNotUploaded((prev) => !prev)}
          open={notUploaded}
        />
        <FileUploaded
          toggle={() => setUploaded((prev) => !prev)}
          open={uploaded}
        />

        {/* Profile section wraper */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          <div className="col-span-2 ">
            {/* top banner profile section  */}

            <div className={classes["bg-container"]}>
              <div
                className={`${classes["bg-profile"]} EditProfileBgProfile userProfileView`}
                style={{
                  filter: "none",
                  overflow: flag2 ? "auto" : "hidden",
                }}
              >
                {image && image.length > 0 ? (
                  image.map((images, index) => {
                    return (
                      <img
                        src={images.url}
                        alt={images.name}
                        key={index}
                        style={{ width: "100%" }}
                      />
                    );
                  })
                ) : (
                  <div
                    className="h-[230px] bg-center bg-cover bg-no-repeat"
                    style={{ width: "100%" }}
                  ></div>
                )}
              </div>

              {!flag2 && (
                <div
                  className="w-full h-[119px] rounded-t-[20px]"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    boxShadow: "inset 0px 108px 56px -31px #86868670",
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                >
                  {/* banner top text  */}
                  <div
                    className="flex items-center justify-between px-10 pt-5 UserProfileView"
                    style={{ padding: "0.8rem 1rem", paddingBottom: 0 }}
                  >
                    <div className="flex items-center justify-center">
                      <img
                        src={LeftArrowIcon}
                        alt="Left Arrow Icon"
                        className="text-white h-[22px] w-[22px] mr-3"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(-1)}

                      />
                      <h3 className="text-[22px] font-bold text-white top_left_side_banner_name">
                        Solly Sherman-Burns
                      </h3>
                    </div>
                    <div
                      className="relative"
                      style={{ cursor: "pointer" }}
                      ref={CHcover}
                    >
                      <BsThreeDotsVertical
                        className="text-white h-[22px] w-[22px]"
                        onClick={() => setFlag1(!flag1)}
                      />

                      {flag1 && (
                        <div
                          className="option"
                          style={{ top: "2.3rem", right: "-10px" }}
                        >
                          <div
                            className="option1"
                            style={{ position: "relative" }}
                          >
                            <p>Change Cover</p>
                            <input
                              type="file"
                              name="myfile"
                              onChange={fileChange}
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                          <div className="option1" onClick={resetCoverFunction}>
                            <p>Remove Cover</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {flag2 && (
                <div
                  style={{
                    position: "absolute",
                    right: "0.5rem",
                    top: "0.5rem",
                  }}
                >
                  <button
                    style={{
                      color: "rgb(255, 255, 255)",
                      fontWeight: "500",
                      borderRadius: "5px",
                      width: "85px",
                      margin: "0 0.5rem",
                      backgroundColor: "rgb(0, 0, 0)",
                    }}
                    onClick={resetCoverFunction}
                  >
                    Cancel
                  </button>
                  <button
                    className="SaveChangesStyle"
                    onClick={changeCoverFunction}
                    style={{
                      color: "rgb(0, 0, 0)",
                      fontWeight: "500",
                      borderRadius: "5px",
                      width: "120px",
                      margin: "0 0.5rem",
                      background: "rgb(255, 160, 0)",
                    }}
                  >
                    Save changes
                  </button>
                </div>
              )}
            </div>

            <div className="block sm:flex items-start justify-start">
              {/* profile image */}
              <div className="w-[150px] h-[150px] bg-white p-[5px] mx-auto sm:mx-[34px] rounded-full -mt-[77px] reponsiveDesignUserProfile" style={{ boxShadow: "rgba(204, 204, 204, 0.75) 0px 0px 5px 0px", zIndex: 1 }}>
                <img
                  className="mx-auto w-full h-full rounded-full z-0"
                  src={photoURL ?? profile2}
                  alt="Profile"
                  style={{
                    zIndex: "1",
                    position: "relative"
                  }}
                />
                <label htmlFor="profilePhoto" style={{
                  zIndex: "1",
                  position: "relative"
                }}>
                  <div
                    className="bg-primary h-[30px] w-[30px] rounded-full flex items-center justify-center -mt-[50px] ml-[115px] relative"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={CameraIcon}
                      alt="camera"
                      className="text-white text-xl"
                    />

                    <input
                      accept="image/*"
                      id="profilePhoto"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleChange}
                    />
                  </div>
                </label>
              </div>

              {/* profile content */}
              <div className="mt-4">
                <div className="userProfileContentResponsiveDesign">
                  <div className="flex items-center justify-center sm:justify-between">
                    <div>
                      <h3 className="text-[20px] font-bold text-[#fff] ">
                        Raging Bull
                      </h3>
                      <p className="text-[#707B88] text-lg font-normal">
                        <span className="text-[#fea500]">
                          @Raging Bull
                        </span>
                      </p>
                    </div>
                  </div>

                  <p className="text-sm sm:text-lg text-center sm:text-left leading-loose pt-5 pb-5 mx-5 sm:mx-0 sm:pr-8 font-[400] text-[#707B88] userProfileContent ">
                    They say money can’t buy happiness… but it can buy yoga
                    classes. And that’s basically the same thing. If you want to
                    know the correct way to perform an exercise, the answer is:
                    Whatever hurts most.
                  </p>

                  {/* provile activity */}
                  <div className="font-bold flex items-center justify-around sm:justify-start sm:gap-6">
                    <div className="flex items-center justify-between flex-col sm:flex-row text-[#444444] changeStyle">
                      <span className="text-base sm:text-lg -mb-1 sm:-m-0 sm:mr-1 changeStyleVal text-[#ffa000]">
                        9
                      </span>
                      <span className="text-base sm:text-lg text-[#ffffff80]">Posts</span>
                    </div>

                    <div className="flex items-center justify-between flex-col sm:flex-row text-[#444444] changeStyle" style={{ cursor: "pointer" }} onClick={() => setShowMember(!showMember)}>
                      <span className="text-base sm:text-lg -mb-1 sm:-m-0 sm:mr-1 changeStyleVal  text-[#ffa000]">
                        250
                      </span>
                      <span className="text-base sm:text-lg text-[#ffffff80]">Members</span>
                    </div>

                    <div className="flex items-center justify-between flex-col sm:flex-row text-[#444444] changeStyle">
                      <span className="text-base sm:text-lg -mb-1 sm:-m-0 sm:mr-1 changeStyleVal  text-[#ffa000]">
                        282
                      </span>
                      <span className="text-base sm:text-lg text-[#ffffff80]">Upvotes</span>
                    </div>
                  </div>
                </div>

                {/* button */}
                <div className="flex items-center justify-around sm:justify-start pt-6 sm:pt-5 gap-4 sm:gap-3 mx-5 sm:mx-0 groupOfButtons">
                  <button
                    className="py-[9px] bg-primary text-black rounded-md text-[18px] w-full sm:w-[145px] flex items-center justify-center"
                    style={{ boxShadow: "0 4px 32px 0 rgba(0, 0, 0, 0.08)" }}
                    onClick={() => setEditGroup(!editGroup)}
                  >
                    <img
                      src={EditIcon}
                      alt="edit icon"
                      className="text-white mr-1 "
                    />
                    Edit Group
                  </button>

                  <button
                    onClick={() => setShowInvite(!showInvite)}
                    className="py-2 border-primary text-primary rounded-md border-solid border-[0.9px] text-[18px] font-bold w-full sm:w-[145px]"
                  >
                    Invite
                  </button>
                </div>
              </div>
            </div>

            {/* divider */}
            <hr className="w-[100%] mt-8 mx-auto text-[#f3f3f31f]" />

            {/* {Mochat post section} */}
            <Outlet />
          </div>

          {/* Right part mochat */}
          {/* Mobile profile responsive  */}
          <div>
            {/* view profile  */}
            <LeftSideProfileSection />

            <div className="hidden sm:block mt-64">
              {/* create post & group */}
              <div className="w-full px-[49px] py-[37px] rounded-xl shadow-[0_4px_32px_rgba(0,0,0,0.08)]" style={{ backgroundColor: "#131313" }}>
                <div>
                  <img
                    src={companyLogo4}
                    alt="logo"
                    className="mx-auto"
                    style={{ width: "42px", height: "41px" }}
                  />
                </div>

                <div className="mt-6 createBtnSection">
                  <button
                    onClick={() => setTopic(true)}
                    className="bg-primary w-full h-[50px] mb-4 text-white rounded-md flex items-center justify-center createPostBtn"
                  >
                    Create Post
                  </button>

                  <button
                    onClick={() => setShowCreate(true)}
                    className="bg-white text-[#000000] w-full h-[50px] border-solid border-2 border-[#000000] rounded-md  flex items-center justify-center createGroupBtn"
                  >
                    Create Group
                  </button>
                </div>
              </div>

              {/* Top Group section  */}
              <div>
                <div className="flex items-center justify-between my-7">
                  <span className="w-[127] h-[38px] text-[#fff] text-[28px] font-[500]">
                    Top Groups
                  </span>
                  <button className="bg-primary w-[110px] h-[36px] text-black rounded-md viewAllBtn" onClick={() => setViewGroup(true)}>
                    View All
                  </button>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex justify-between pb-5">
                    <div className="flex ">
                      <div className="w-1/8">
                        <img
                          className="w-full h-[70px] rounded-xl"
                          src={movie1}
                          alt="logo"
                        />
                      </div>
                      <div className="px-3">
                        <div className="flex items-center justify-between pb-1 text-gray">
                          <h3 className="text-[20px] font-medium  text-[#fff]">
                            Cool Group Number 1
                          </h3>
                        </div>
                        <p className="text-[18px] font-[300] pt-2 text-[#868B90]">
                          Private · 1M members · 10+ posts a day
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-9px] items-center justify-center rounded-full addGroupCircle">
                    <a href="/">
                      <AiOutlinePlus className="text-[#FEA500] text-xl" />
                    </a>
                  </div>
                </div>

                {/* divider */}
                <hr className="w-[100%] mx-auto" style={{ backgroundColor: "rgba(255, 255, 255, 0.12)" }} />

                <div className="flex items-center justify-between">
                  <div className="flex justify-between py-5">
                    <div className="flex ">
                      <div className="w-1/8">
                        <img
                          className="w-full h-[70px] rounded-xl"
                          src={movie2}
                          alt="logo"
                        />
                      </div>
                      <div className="px-3">
                        <div className="flex items-center justify-between pb-1 text-gray">
                          <h3 className="text-[20px] font-medium  text-[#fff]">
                            Cool Group Number 2
                          </h3>
                        </div>
                        <p className="text-[18px] font-[300] pt-2 text-[#868B90]">
                          Private · 1M members · 10+ posts a day
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-7px] items-center justify-center rounded-full addGroupCircle">
                    <a href="/">
                      <AiOutlinePlus className="text-[#FEA500] text-xl" />
                    </a>
                  </div>
                </div>

                {/* divider */}
                <hr className="w-[100%] mx-auto" style={{ backgroundColor: "rgba(255, 255, 255, 0.12)" }} />

                <div className="flex items-center justify-between">
                  <div className="flex justify-between py-5">
                    <div className="flex ">
                      <div className="w-1/8">
                        <img
                          className="w-full h-[70px] rounded-xl"
                          src={movie3}
                          alt="logo"
                        />
                      </div>
                      <div className="px-3">
                        <div className="flex items-center justify-between pb-1 text-gray">
                          <h3 className="text-[20px] font-medium  text-[#fff]">
                            Cool Group Number 3
                          </h3>
                        </div>
                        <p className="text-[18px] font-[300] pt-2 text-[#868B90]">
                          Private · 1M members · 10+ posts a day
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-7px] items-center justify-center rounded-full addGroupCircle">
                    <a href="/">
                      <AiOutlinePlus className="text-[#FEA500] text-xl" />
                    </a>
                  </div>
                </div>

                {/* divider */}
                <hr className="w-full mx-auto" style={{ backgroundColor: "rgba(255, 255, 255, 0.12)" }} />

                <div className="flex items-center justify-between">
                  <div className="flex justify-between py-5">
                    <div className="flex ">
                      <div className="w-1/8">
                        <img
                          className="w-full h-[70px] rounded-xl"
                          src={movie4}
                          alt="logo"
                        />
                      </div>
                      <div className="px-3">
                        <div className="flex items-center justify-between pb-1 text-gray">
                          <h3 className="text-[20px] font-medium  text-[#fff]">
                            Cool Group Number 2
                          </h3>
                        </div>
                        <p className="text-[18px] font-[300] pt-2 text-[#868B90]">
                          Private · 1M members · 10+ posts a day
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-7px] items-center justify-center rounded-full addGroupCircle">
                    <a href="/">
                      <AiOutlinePlus className="text-[#FEA500] text-xl" />
                    </a>
                  </div>
                </div>

                {/* divider */}
                <hr className="w-[100%] mx-auto" style={{ backgroundColor: "rgba(255, 255, 255, 0.12)" }} />
              </div>

              {/* <div className="flex items-center justify-center">
                <Link
                  className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                  to="/Agreement"
                  reloadDocument={true}
                >
                  User Agreement
                </Link>
                <span className="text-[#dddddd] mt-[21px]">|</span>
                <Link
                  className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                  to="/PrivacyPolicy"
                  reloadDocument={true}
                >
                  Privacy Policy
                </Link>
                <span className="text-[#dddddd] mt-[21px]">|</span>
                <Link
                  className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                  to="/AcceptableUsePolicy"
                  reloadDocument={true}
                >
                  Acceptable Use Policy
                </Link>
                <span className="text-[#dddddd] mt-[21px]">|</span>
                <Link
                  className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                  to="/Disclaimer"
                  reloadDocument={true}
                >
                  Disclaimer
                </Link>
              </div> */}
              {/* message box */}
              <Message />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </React.StrictMode>
  );
};

export default AdminCommunityGroup;
