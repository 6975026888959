import React, { useEffect, useRef, useState } from "react";

// Router
import { useNavigate, useParams } from "react-router-dom";

// imoji picker library
import EmojiPicker from "emoji-picker-react";

// Images
import { logo4, user1, user2 } from "../../constant/imagePath";
import {
  Send1Icon,
  EmojiIcon,
  BlockUserIcon,
  EyeIcon,
} from "../../constant/IconsPath";

//react icons
import { BiDotsVerticalRounded } from "react-icons/bi";

// external scrolling library
import ReactScrollableFeed from "react-scrollable-feed";

// react redux
import { useDispatch, useSelector } from "react-redux";
import { add } from "../../Store/MessagesSlice";
import { addChat, isChatShow } from "../../Store/ChatSlice";
import { add as toggle } from "../../Store/MessageLeftSideShowAndHide";

// component
import { ConfirmationDialogBox } from "../Models/Index";

// awesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

const MessageBox = () => {
  const param = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const data = useSelector((state) => state.messages[param.id - 1]);
  const flag = useSelector((state) => state.showAndHide);
  const [confirmationMessage, setConfirmationMessage] = useState(false);


  const [flag1, setFlag1] = useState(false);

  useEffect(() => {
    // set the overflow y-axis to the bottom
    let main_part = document.querySelector(".main_part");
    main_part.scrollTo(0, main_part.scrollHeight);
  }, [data]);

  // set user input value in this state
  const [inputValue, setInputValue] = useState("");
  // taggle the emoji
  const [showEmoji, setShowEmoji] = useState(false);
  // set the current input field value
  const inputFieldMessage = useRef("");
  // destructure the value
  let { image, name, status, follower } = data || {};

  // time
  let date = new Date();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  var prepand = hour >= 12 ? " PM " : " AM ";

  hour = hour >= 12 ? hour - 12 : hour;
  if (hour === 0 && prepand === " PM ") {
    if (minute === 0 && second === 0) {
      hour = 12;
      prepand = " Noon";
    } else {
      hour = 12;
      prepand = " PM";
    }
  }
  if (hour === 0 && prepand === " AM ") {
    if (minute === 0 && second === 0) {
      hour = 12;
      prepand = " Midnight";
    } else {
      hour = 12;
      prepand = " AM";
    }
  }

  minute = minute < 10 ? `0${minute}` : minute;

  let time = `${hour}:${minute} ${prepand}`;

  // this function trigger when click on send icon
  const msgSendFunction = () => {
    const messageText = inputFieldMessage.current.innerText.trim(); // Trim whitespace

    if (messageText !== "") {
      const message = {
        id: param.id - 1,
        who: "sender",
        msg: messageText,
        time: time,
      };

      dispatch(add(message));
      // set input empty string
      inputFieldMessage.current.innerText = "";

      // set the overflow y-axis to the bottom
      let main_part = document.querySelector(".main_part");
      main_part.scrollTo(0, main_part.scrollHeight);
    }
  };

  const left_side_visible_or_hide = () => {
    const one = document.querySelector(".main_container .left_side .users");
    const two = document.querySelector(".front_side .main_part");
    const three = document.querySelector(".front_side .bottom_part");

    const oneStyle = window.getComputedStyle(one);

    if (oneStyle.visibility === "visible") {
      one.style.visibility = "hidden";
      one.style.transform = "translateX(-1010px)";
      two.style.width = "100%";
      three.style.width = "91.5%";
      dispatch(toggle(true));
    } else {
      one.style.visibility = "visible";
      one.style.transform = "translateX(0px)";
      two.style.width = "calc(100% - 97px)";
      three.style.width = "calc(100% - 126px)";
      dispatch(toggle(false));
    }
  };

  const setDataFunction = () => {
    dispatch(addChat(param.id - 1));
    dispatch(isChatShow(true));
    navigate(-1);
  };

  const navigateAnotherPage = () => {
    let arrangeData = {
      ...data,
      img: data.image,
      followers: data.follower
    }
    navigate("/userProfile", { state: arrangeData });
  };

  const sucessFullyBlockUser = () => {
    setConfirmationMessage(false);
  };
  const cancelBlockUser = () => {
    console.log("Successfully cancel action to block a user");
    setConfirmationMessage(false);
  };

  return (
    <React.StrictMode>
      <ConfirmationDialogBox
        comment={"Are you sure you want to block this person?"}
        buttonText1={"Yes"}
        buttonText2={"Cancel"}
        firstBTNFN={sucessFullyBlockUser}
        secondBTNFN={cancelBlockUser}
        setConfirmationMessage={setConfirmationMessage}
        confirmationMessage={confirmationMessage}
      />

      {/* front part */}
      <div className="front_side">
        {/* top part */}
        <div className="front_side_main_top_part">
          {/* hamburger */}
          <div className="hamburger">
            {flag ? (
              <FontAwesomeIcon
                icon={faBars}
                onClick={left_side_visible_or_hide}
              />
            ) : (
              <FontAwesomeIcon
                icon={faXmark}
                onClick={left_side_visible_or_hide}
              />
            )}
          </div>

          <div className="front-side_top_part">
            {/* left side */}
            <div className="left_side">
              <img
                src={image ?? user2}
                alt="user"
                style={{ borderRadius: "50%" }}
              />
              {/* content */}
              <div className="content">
                <h6 className="text-[#fff]">{name ?? "George Smith"}</h6>
                <p

                >
                  {/* fill circle */}
                  <span
                    style={{
                      backgroundColor:
                        status === "Offline" ? "#636363" : "#2da771",
                    }}
                    className="Circle"
                  ></span>
                  <span style={{
                    color: status === "Offline" ? "#636363" : "#2da771",
                  }}>
                    {status ?? "Online"}
                  </span>
                </p>
              </div>
            </div>

            {/* right side */}
            <div className="right_side">
              <div
                className="vertical_icon"
                onClick={() => setFlag1((val) => !val)}
              >
                <BiDotsVerticalRounded />
              </div>

              {/* mini container */}
              {flag1 && (
                <div className="mini_container">
                  <ul>
                    <li onClick={setDataFunction}>Open in chat</li>
                    <li>Mute notifications</li>
                    <li>Clear messages</li>
                    <li>Delete messages</li>
                    <li>Report</li>
                    <li>Block</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Main part */}
        <div className="main_part">
          <ReactScrollableFeed>
            {data && data.messages?.length > 0 &&
              data.messages.map((val, index) => {
                let { msg, time, who } = val;
                if (who === "reciver") {
                  // second person
                  return (
                    <div className="second_person" key={index}>
                      {/* user profile picture and message */}
                      <div className="second_person_top_part">
                        <img
                          src={image ?? user1}
                          alt={"user profile"}
                        />

                        <p>{msg ?? "---"}</p>
                      </div>
                      {/* time */}
                      <div className="time">
                        <p>{time ?? "7:29 P.M"}</p>
                      </div>
                    </div>
                  );
                } else {
                  //   first person
                  return (
                    <div className="first_person" key={index}>
                      {/* user profile picture and message */}
                      <div className="first_person_top_part">
                        <p>{msg ?? "---"}</p>
                        <img src={logo4} alt={"user profile"} />
                      </div>
                      {/* time */}
                      <div className="time">
                        <p>{time ?? "7:29 P.M"}</p>
                      </div>
                    </div>
                  );
                }
              })}

            {data && data.hasOwnProperty("messages") ? (
              ""
            ) : (
              <p
                style={{
                  textAlign: "center",
                  color: "#848b90",
                  margin: "2rem 0",
                }}
              >
                Empty
              </p>
            )}
          </ReactScrollableFeed>
        </div>

        {/* bottom part */}
        <div className="bottom_part">
          <p
            suppressContentEditableWarning={true}
            contentEditable={true}
            className="sendMessageField"
            ref={inputFieldMessage}
          >
            {inputValue}
          </p>
          {/* send button and emojy */}
          <div className="sendButton">
            <img
              src={EmojiIcon}
              alt={"emojy icon"}
              onClick={() => setShowEmoji((val) => !val)}
            />
            <img src={Send1Icon} alt={"send icon"} onClick={msgSendFunction} />
          </div>
        </div>

        {/* emoji */}
        {showEmoji && (
          <EmojiPicker
            onEmojiClick={(event) => {
              setInputValue(
                `${inputFieldMessage.current.innerText} ${event.emoji}`
              );
            }}
            autoFocusSearch={false}
            searchDisabled
            width="100%"
            lazyLoadEmojis={true}
          />
        )}
      </div>

      {/* right side */}
      <div className="rightSide">
        <img
          src={image ?? user2}
          alt="user profile"
          className="userProfilePicture"
          style={{ borderRadius: "50%" }}
        />
        <div className="namefollower">
          <h5 className="text-[#fff]">{name ?? "George Smith"}</h5>
          <p>
            <span className="text-[#FEA500]">
              {follower ?? "250"} Followers
            </span>
          </p>
        </div>
        {/* buttons */}
        <div className="btnGroup">
          {/* View profile */}
          <button className="viewProfile" onClick={navigateAnotherPage}>
            <img src={EyeIcon} alt={"View Icon"} />
            <span className="text-[#000]">View Profile</span>
          </button>

          {/* block user */}
          <button className="blockUser" alt={"Block User"} onClick={() => setConfirmationMessage(!confirmationMessage)}>
            <img src={BlockUserIcon} alt="block user icon" />
            <span>Block User</span>
          </button>
        </div>
      </div>
    </React.StrictMode >
  );
};

export default MessageBox;
