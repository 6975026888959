import React, { useState, useRef, useEffect } from "react";

// React Toolkit
import { useDispatch } from "react-redux";
import { deleteReply, replyAdd } from "../../Store/PostsSlice";

// Components
import EditComment from "./EditComment";

// Emoji Picker
import EmojiPicker from "emoji-picker-react";

// Icons
import {
  DarkEmojiIcon,
  DownArrow1Icon,
  TopArrowIcon,
} from "../../constant/IconsPath";
import { logo4 } from "../../constant/imagePath";

// Font Awesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";

const NestedComment = ({ content }) => {
  const { id, parentId, commentId, name, time, reply, replyWith } = content || "";

  const dispatch = useDispatch();

  const [pushAndDown, setPushAndDown] = useState(null);
  const [showNestedCommentTextArea, setShowNestedCommentTextArea] = useState(false);
  const [nestedInputValue1, setNestedInputValue1] = useState("");
  const [nestedShowEmoji1, setNestedShowEmoji1] = useState(false);
  const nestedInputFieldMessage1 = useRef("");
  const EmojiIcon11 = useRef("");
  const [isEmpty2, setIsEmpty2] = useState(true);
  const [responsiveNestedCommentFunction, setResponsiveNestedCommentFunction] =
    useState(false);
  const [isEditNested, setIsEditNested] = useState(false);

  const responsiveNestedCommentFn = useRef("");

  useEffect(() => {
    const handler123 = (e) => {
      if (responsiveNestedCommentFn.current && !responsiveNestedCommentFn.current.contains(e.target)) {
        setResponsiveNestedCommentFunction(false);
      }
    };

    document.addEventListener("mousedown", handler123);

    return () => {
      document.removeEventListener("mousedown", handler123);
    };
  }, []);

  const nestedCancelReplyFunction1 = () => {
    setNestedInputValue1("");
    setShowNestedCommentTextArea(false);
  };

  const nestedReplyAddFunction1 = () => {
    const random = Math.random();
    const obj = {
      parentId,
      commentId,
      reply: {
        parentId,
        commentId,
        id: random,
        name,
        reply: nestedInputFieldMessage1.current.innerText,
        replyDown: 0,
        replyPush: 0,
        replyWith: "Sally",
        time: "1 min ago"
      }
    };

    dispatch(replyAdd(obj));

    setShowNestedCommentTextArea(false);
    setNestedInputValue1("");
    setNestedShowEmoji1("");
    setIsEmpty2(true);
  };

  const replyFn = () => {
    setShowNestedCommentTextArea(true);
  };

  const checkingIsEmptyOrNot = () => {
    setIsEmpty2(nestedInputFieldMessage1.current.innerText.trim().length === 0);
  };

  const checkingIsEmptyOrNot1 = (element) => {
    setIsEmpty2(false);
    setNestedInputValue1(`${nestedInputFieldMessage1.current.innerText} ${element.emoji}`);
  };

  const editCommentFn = () => {
    setIsEditNested(true);
    setResponsiveNestedCommentFunction(false);
  };

  const delete_comment = () => {
    const obj = {
      parentId,
      commentId,
      replyId: id
    };

    dispatch(deleteReply(obj));

    setResponsiveNestedCommentFunction(false);
  };

  const updateComment = () => {
    setIsEditNested(false);
  };


  return (
    <React.StrictMode>
      {isEditNested ? (
        <EditComment
          comment={reply}
          UpdateComment={updateComment}
          setIsEdit={setIsEditNested}
          styleApply={{
            width: "90%",
            responsiveWidth: "reponsiveWidth",
            justifyContent: "flex-end",
            imageSize: "30px",
          }}
          name={"nestedComment"}
          commentId={commentId}
          parentId={parentId}
          replyId={id}
        />
      ) : (
        <div className="mobileResponsive nestedMobileResponsive">
          <div className="mobileResponsive_left_side">
            {/* image */}
            <img src={logo4} alt="user" />
          </div>
          <div className="mobileResponsive_right_side">
            <div className="top_area">
              <div className="flex items-center justify-start responsive_comment_design">
                <h3 className="text-lg font-md text-[#fff]">
                  {name ?? "Michael Bruno"}
                </h3>
                <p className="text-xs ml-3 text-[#999999]">
                  {time ?? "8 min ago"}
                </p>
              </div>
              <div
                className="top_area_right_side"
                ref={responsiveNestedCommentFn}
              >
                <FontAwesomeIcon
                  icon={faEllipsis}
                  className="horizontalThreeDotsIcon"
                  onClick={() =>
                    setResponsiveNestedCommentFunction(
                      !responsiveNestedCommentFunction
                    )
                  }
                />
                {responsiveNestedCommentFunction && (
                  <div className="commentFunctionality">
                    {/* edit comment */}
                    <div className="editComment" onClick={editCommentFn}>
                      <FontAwesomeIcon icon={faPen} color="#fea500" />
                      <p>Edit Comment</p>
                    </div>

                    {/* Delete comment */}
                    <div className="deleteComment" onClick={delete_comment}>
                      <FontAwesomeIcon icon={faTrash} color="#fea500" />
                      <p>Delete Comment</p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="comment_area">
              {content?.replyWith ? (
                <div className="text targetNameAndComment">
                  <p
                    className="text-[#868B90] font-light text-base mb-2 user_comment_design"
                    style={{ padding: "0 0.5rem", paddingLeft: 0 }}
                  >
                    <span className="FrontSide">{replyWith}</span> <span style={{ paddingLeft: "0.3rem" }}>{reply ?? "---"}</span>
                  </p>
                </div>
              ) : (
                <p className="text-[#868B90] font-light text-base mb-2 user_comment_design">
                  <span>{reply ?? "---"}</span>
                </p>
              )}
            </div>

            <div className="bottomArea">
              <div className="bottomArea_left_side">
                <div
                  className="flex items-center justify-center push_arrow cursor-pointer upWardStyle"
                  onClick={() => setPushAndDown(true)}
                >
                  <TopArrowIcon topArrow_color={pushAndDown === null ? "#999999" : pushAndDown ? "#ffa000" : "#999999"} width="20" height="20" />
                  <p
                    className="text-[17px]"
                    style={{ marginLeft: "0.1rem", color: pushAndDown === null ? "#999999" : pushAndDown ? "#ffa000" : "#999999" }}
                  >
                    {pushAndDown === null ? 0 : pushAndDown ? 1 : 0}
                  </p>
                </div>

                <div
                  className="flex items-center justify-center ml-2 down_arrow cursor-pointer downWardStyle"
                  onClick={() => setPushAndDown(false)}
                >
                  <DownArrow1Icon downArrow_color={pushAndDown === null ? "#999999" : pushAndDown ? "#999999" : "#ffa000"} width="15" height="15.78" />
                  <p
                    className="ml-1"
                    style={{ fontSize: "17px", color: pushAndDown === null ? "#999999" : pushAndDown ? "#999999" : "#ffa000" }}
                  >
                    {pushAndDown === null ? 0 : pushAndDown ? 0 : 1}
                  </p>
                </div>
              </div>

              <div className="bottomArea_right_side">
                <button
                  className="text-primary text-base user_comment_replay_design"
                  onClick={replyFn}
                  style={{ cursor: "pointer", border: "none", background: "none" }}
                >
                  Reply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showNestedCommentTextArea && (
        <div className="reply">
          {/* left side */}
          <div className="reply_leftSide">
            <img src={logo4} alt="user icon" width={"auto"} height={"auto"} />
          </div>

          {/* right side */}
          <div className="reply_rightSide">
            <div className="arrangeNameAndText">
              <span className="FrontSide">{name}</span>
              <p
                className="typingArea"
                contentEditable={true}
                suppressContentEditableWarning={true}
                ref={nestedInputFieldMessage1}
                onKeyUp={(e) => checkingIsEmptyOrNot(e)}
              >
                {nestedInputValue1}
              </p>
            </div>

            <div className="replyBottomPart">
              {/* emoji */}
              <div className="emojiIcon" ref={EmojiIcon11}>
                {/* emoji icon */}
                <img
                  src={DarkEmojiIcon}
                  alt="Emoji Icon"
                  onClick={() => setNestedShowEmoji1((val) => !val)}
                  width={"100%"}
                  height={"100%"}
                />

                {/* emoji */}
                {nestedShowEmoji1 && (
                  <EmojiPicker
                    onEmojiClick={(event) => {
                      checkingIsEmptyOrNot1(event)
                    }}
                    autoFocusSearch={false}
                    searchDisabled
                    width="100%"
                    lazyLoadEmojis={true}
                  />
                )}
              </div>

              {/* buttons */}
              <div className="funtionButton">
                <button onClick={nestedCancelReplyFunction1}>Cancel</button>
                <button
                  className="active1"
                  onClick={isEmpty2 ? null : nestedReplyAddFunction1}
                  style={{
                    opacity: isEmpty2 ? 0.6 : 1,
                    cursor: isEmpty2 ? "not-allowed" : "pointer",
                  }}
                  disabled={isEmpty2 ? true : false}
                >
                  Reply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.StrictMode>
  );
};

export default NestedComment;
