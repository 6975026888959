import React, { useState } from "react";

// Router
import { NavLink, Outlet } from "react-router-dom";

// images
import { SearchIcon } from "../../constant/IconsPath";

// icons
import { BiLeftArrowAlt } from "react-icons/bi";
import { BiUserCircle } from "react-icons/bi";

// data
import { useDispatch, useSelector } from "react-redux";

// style
import "../../style/Message.css";

// compnents
import { Header } from "../../components";

// font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

// Redux Toolkit
import { add } from "../../Store/MessageLeftSideShowAndHide";

const Messages = () => {
  let data = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  // searching message store in this array
  let [sortingData, setSortingData] = useState([]);

  const [flag, setFlag] = useState(true);
  const [flag1, setFlag1] = useState(true);
  // when searching messages if it needs time to match the result then this state controls the loader
  const [loader, serLoader] = useState(false);
  // if searching messages is not found this state displays the message
  const [notFound, setNotFound] = useState("");
  // user search value stored in this state
  const [searchMessages, setSearchMessages] = useState("");

  let search_area_box = document.querySelector(".searchBar .search_area_box");
  const sortingValueFunction = (e) => {
    serLoader(true);
    //when this "if" block executes if the user clicks on the search field but does not type anything
    if (e === "") {
      // everything reset
      setSortingData([]);
      setNotFound("");
      setSearchMessages("");
      search_area_box.style.display = "none";
    } else {
      search_area_box.style.display = "block";
      // check user search value exists in the "data state" and store it "sortingData"
      sortingData = data.filter((value) =>
        value.name.toLocaleLowerCase().includes(e.toLocaleLowerCase())
      );

      // set the value that the user type in the search field
      setSearchMessages(e);

      // this "if" block executes when the user search message does not exist in the data
      if (sortingData.length === 0) {
        setNotFound(e);
        setSortingData([]);
        setSearchMessages("");
      } else {
        setSortingData(sortingData);
        setNotFound("");
      }
    }
    serLoader(false);
  };

  const OnpenSearchArea = (event) => {
    event.preventDefault();
    let searchArea = document.querySelector(".searchArea");

    if (flag1) {
      setFlag(false);
      setFlag1(false);
    }
    searchArea.focus();
  };

  const CloseSearchArea = (event) => {
    event && event.preventDefault();
    setFlag1(true);
    setFlag(true);
    setSearchMessages("");
    search_area_box.style.display = "none";
  };

  const sortSearchMessagesComponent = () => {
    return (
      <div>
        {searchMessages && (
          <div className="search_bar_bottom_label">
            {/* search icon */}
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="text-secondary text-3xl mobileResponsiveIcon"
              style={{ width: "13px", height: "13px", color: "#fff" }}

            />
            <p style={{ padding: "0 0.7rem" }}>
              Search messages for "
              <span className="text-[#fff]">{searchMessages}</span>"
            </p>
          </div>
        )}
        {sortingData &&
          sortingData.length > 0 &&
          sortingData.map((value) => {
            let { id, image, name } = value;
            return (
              <NavLink to={`/messages/${id}`} state={value} key={id}>
                <div
                  className="message1"
                  onClick={() => {
                    CloseSearchArea();
                  }}
                >
                  {/* left side user profile picture */}
                  <div className="userProfile">
                    {image ? (
                      <img
                        src={image}
                        alt="user profile"
                      />
                    ) : (
                      <BiUserCircle
                        style={{ fontSize: "3rem", color: "#c6c6c6" }}
                      />
                    )}
                  </div>

                  {/* right side content */}
                  <div
                    className="userDetail"
                    style={{ display: "flex", marginLeft: "1.5rem" }}
                  >
                    <div className="userDetail_topPart">
                      {/* name */}
                      <p className="user_name">{name ?? "Williams"}</p>

                      {/* time */}
                      <p className="time">
                        {value &&
                          value.hasOwnProperty("messages") &&
                          value.messages[value.messages.length - 1].time
                          ? value.messages[value.messages.length - 1].time
                          : "7:29 P.M"}
                      </p>
                    </div>

                    <p className="message">
                      {value &&
                        value.hasOwnProperty("messages") &&
                        value.messages[value.messages.length - 1].msg
                        ? value.messages[value.messages.length - 1].msg.length >
                          25
                          ? `${value.messages[
                            value.messages.length - 1
                          ].msg.slice(0, 25)}...`
                          : value.messages[value.messages.length - 1].msg
                        : "Hey bro! I just messaged.."}
                    </p>
                  </div>
                </div>
              </NavLink>
            );
          })}
      </div>
    );
  };

  const left_side_visible_or_hide = () => {
    let one = document.querySelector(".main_container .left_side .users");
    let two = document.querySelector(".front_side .main_part");
    let three = document.querySelector(".front_side .bottom_part");

    let oneStyle = window.getComputedStyle(one);

    if (oneStyle.visibility === "visible") {
      one.style.visibility = "hidden";
      one.style.transform = "translateX(-1010px)";
      two.style.width = "100%";
      three.style.width = "91.5%";
      dispatch(add(true));
    } else {
      one.style.visibility = "visible";
      one.style.transform = "translateX(0px)";
      two.style.width = "calc(100% - 97px)";
      three.style.width = "calc(100% - 126px)";
      dispatch(add(false));
    }
  };

  return (
    <React.StrictMode>
      <Header loginAndSignup={false} messageShow={true} messageCurrentPage={true} />
      <section className="main_container containerContent container mx-auto homePageStyle">
        {/* left side */}
        <div className="left_side">
          {/* search area and unread message */}
          <div className="top_part">
            {/* top area */}
            <div className="top_area">
              <h6 className="text-[#fff]">Messages</h6>
              <p>25 Unread</p>
            </div>

            {/* search bar */}
            <div className="flex items-center justify-center searchBar">
              <form
                className="min-[920px]:w-[300px] lg:w-[600px] relative"
                style={{
                  display: !flag && "flex",
                  justifyContent: !flag && "flex-end",
                  alignItems: !flag && "center",
                }}
              >
                <input
                  placeholder="Search message"
                  type="text"
                  className="border border-lightGray rounded-full h-[40px] px-[14px] py-[9px] w-full"
                  onChange={(e) => sortingValueFunction(e.target.value)}
                  onClick={OnpenSearchArea}
                  style={{
                    width: !flag && "85%", background: "transparent",
                    border: "1px solid rgba(199, 199, 199, 0.5)",
                    outline: "none",
                    color: "#fff"
                  }}
                />
                {flag ? (
                  <button
                    className="w-[30px] h-[30px] flex items-center justify-center absolute top-1/2 right-[5px] -translate-y-1/2 bg-primary rounded-full SearchIcon"
                    onClick={OnpenSearchArea}
                  >
                    <a href="/">
                      <img src={SearchIcon} alt={"search icon"} width={"auto"} height={"auto"} />
                    </a>
                  </button>
                ) : (
                  <button
                    className="w-[30px] h-[30px] flex items-center justify-center absolute top-1/2 left-[5px] -translate-y-1/2 bg-primary rounded-full SearchIcon"
                    onClick={CloseSearchArea}
                  >
                    <a href="/">
                      <BiLeftArrowAlt className="text-white text-xl" />
                    </a>
                  </button>
                )}
              </form>

              <div className="search_area_box">
                {!flag ? sortSearchMessagesComponent() : ""}

                {/* not found error message */}
                {notFound && (
                  <div className="nothingFoundError">
                    <h6>Nothing found</h6>
                    <p>
                      We couldn't find any matches for "{notFound}" Try checking
                      for typos or using complete word.
                    </p>
                  </div>
                )}

                {loader && <div className="load"></div>}
              </div>
            </div>
          </div>

          {/* user profile and message*/}
          <div className="users">
            {!flag
              ? sortSearchMessagesComponent()
              : data &&
              data.length > 0 &&
              data.map((value) => {
                let { id, image, name } = value;
                return (
                  <NavLink
                    to={`/messages/${id}`}
                    key={id}
                    onClick={left_side_visible_or_hide}
                  >
                    <div className="message1">
                      {/* left side user profile picture */}
                      <div className="userProfile">
                        {image ? (
                          <img
                            src={image}
                            alt="user profile"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <BiUserCircle
                            style={{ fontSize: "3rem", color: "#c6c6c6" }}
                          />
                        )}
                      </div>

                      {/* right side content */}
                      <div className="userDetail">
                        <div className="userDetail_topPart">
                          {/* name */}
                          <p className="user_name">{name ?? "Williams"}</p>

                          {/* time */}
                          <p className="time">
                            {value &&
                              value.hasOwnProperty("messages") &&
                              value.messages[value.messages.length - 1].time
                              ? value.messages[value.messages.length - 1].time
                              : "7:29 P.M"}
                          </p>
                        </div>

                        <p className="message">
                          {value &&
                            value.hasOwnProperty("messages") &&
                            value.messages[value.messages.length - 1].msg
                            ? value.messages[value.messages.length - 1].msg
                              .length > 25
                              ? `${value.messages[
                                value.messages.length - 1
                              ].msg.slice(0, 25)}...`
                              : value.messages[value.messages.length - 1].msg
                            : "Hey bro! I just messaged.."}
                        </p>
                      </div>
                    </div>
                  </NavLink>
                );
              })}

            {/* not found error message */}
            {notFound && (
              <div className="nothingFoundError">
                <h6>Nothing found</h6>
                <p>
                  We couldn't find any matches for "{notFound}" Try checking for
                  typos or using complete word.
                </p>
              </div>
            )}

            {loader && <div className="load"></div>}
          </div>
        </div>

        {/* main side and right side */}
        <Outlet />
      </section>
    </React.StrictMode>
  );
};

export default Messages;
