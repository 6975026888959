import React, { useState, useEffect } from "react";

// React Router
import { Link, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// images
import { companyLogo4 } from "../constant/LogoPath";
import { logo4, movie1, movie2, movie3, movie4, profile } from "../constant/imagePath";
import { CreateTopicIcon, CreateGroupIcon, CameraIcon, SearchIconDark } from "../constant/IconsPath";

// react icons
import { AiOutlinePlus } from "react-icons/ai";
import { BsQuestionCircle } from "react-icons/bs";

// Components
import GroupCreate from "../components/Models/GroupCreate";
import { PostCreate, Message, BeforeLoginMessage } from "../components/Models/Index";
import { Header } from "../components";
import CropEasy from "../components/Crop/CropEasy";
import ViewAllGroup from "../components/Models/ViewAllGroup/ViewAllGroup";
import StorageService from "../../src/services/StorageService";
import { useNavigate } from "react-router-dom";
import { startLoading, stopLoading } from "../Store/IdentitySlice";

// style
import "../style/home.css";
import axios from "axios";
import { toast } from "react-toastify";
import { updateTrendingPostData, updateTrendingPostDataOnSearch,setSearch} from "../Store/TrendingPageSlice";
import { get, post } from "../api/api";
import Footer from "../components/Footer/Footer";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let {search} = useSelector((state) => state.Trending);
  console.log("search",search);
  const dispatch = useDispatch();
  const [showCreate, setShowCreate] = useState(false);
  const [topic, setTopic] = useState(false);
  const [question, setQuestion] = useState(false);
  const [photoURL, setPhotoURL] = useState();
  const [openCrop, setOpenCrop] = useState(false);
  const [flag, setFlag] = useState(true);
  // const [search, setSearch] = useState({ searchValue: " ", isSearched: false });
  const [flag1, setFlag1] = useState(true);
  const [flag2, setFlag2] = useState(true);
  const [viewGroup, setViewGroup] = useState(false);
  const [Group, setGroupData] = useState([]);
  const [token, setToken] = useState("");
  const someValue = StorageService.getValue("userName");
  const someProfile = StorageService.getValueProfilePic("profilePic");
  const [messageForLogin, setMessageForLogin] = useState(false);

  console.log((photoURL || someProfile !="null"),"photoURL",someProfile)
  
console.log("Group",Group);
  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setOpenCrop(true);
      setPhotoURL(URL.createObjectURL(file));
    } else {
      setOpenCrop(false);
      setPhotoURL();
    }
  };
  useEffect(() => {
    setToken(StorageService.getJwt());
    // dispatch(startLoading());
    const timer = setTimeout(() => {
      getGroup();
    }, 2000);

    return () => clearTimeout(timer);
  }, [!token]);
  const getGroup = async () => {
    // dispatch(startLoading());
    try {
      let res = await get("/Group/ReadGroups");
      if (res) {
        setGroupData(res);
        // dispatch(stopLoading());
      } else {
        // dispatch(stopLoading());
      }
    } catch (error) {
      console.error("Error fetching trending posts:", error);
      // dispatch(stopLoading());
    }
  };

  const openPopUp = (e) => {
    e.preventDefault();
    if(token){
      setTopic(true);
    }else{
      setMessageForLogin(!messageForLogin)
    }
  };


  useEffect(() => {
    if (search.searchValue.length == 0) {
      getTopPosts("", false);
    }
  }, []);

  const getTopPosts = async (searchedValue, isSearch, whenSearchEmpty = false) => {
    try {
      const pageName = {
        "/": "TrendingPost",
        "/top-post": "topPost",
        "/new-post": "New",
        "/for-you": "ForYou",
      };
      if ((searchedValue && isSearch) || whenSearchEmpty) {
        // dispatch(startLoading());
        let endPoint = `/Post/TopVotedIn24Hours?pageNumber=0&pageSize=3&search=${searchedValue}&userId=${StorageService?.getValue("userId") ? StorageService?.getValue("userId") : null}`;
        if (pageName[location.pathname] === "New") {
          endPoint = `/Post/ReadLatestPost?pageNumber=0&pageSize=3&search=${searchedValue}&userId=${StorageService?.getValue("userId") ? StorageService?.getValue("userId") : null}`;
        } else if (pageName[location.pathname] === "topPost") {
          endPoint = `/Post/TopVotedPosts?pageNumber=0&pageSize=3&search=${searchedValue}&userId=${StorageService?.getValue("userId") ? StorageService?.getValue("userId") : null}`;
        } else if (pageName[location.pathname] === "ForYou") {
          endPoint = `/Post/GetUserGroupData?pageNumber=0&pageSize=3&search=${searchedValue}`;
        }
        let res = await post(endPoint);

        let data = await res.json();

        if (res && data) {
          dispatch(updateTrendingPostDataOnSearch(data));
          dispatch(stopLoading());
          return res.data;
        } else {
          dispatch(stopLoading());
        }
      }
    } catch (error) {
      console.error("Error fetching top trending posts:", error);
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (search.isSearched) {
        getTopPosts(search.searchValue, true);
      } else {
        getTopPosts("", false);
      }
    }, 500);

    return () => clearTimeout(debounce);
  }, [search.searchValue]);

  const HandleSearch = (e) => {
    if (!e.target.value) {
      getTopPosts("", true, true);
    }
    dispatch(setSearch({ searchValue: e.target.value, isSearched: true }))
  };

 

  return (
    <React.StrictMode>
      {/* Header */}
      <Header loginAndSignup={StorageService?.getJwt()? false:true} messageShow={true} setMessageForLogin={setMessageForLogin} />
      <BeforeLoginMessage helpModal={messageForLogin} setHelpModal={setMessageForLogin} />
      <div className="container mx-auto sm:mt-12 containerContent homePageStyle">
        <GroupCreate open={showCreate} setShowCreate={setShowCreate} titleShow={true} flag2={flag2} setFlag2={setFlag2} userNameShow={true} />

        <PostCreate open={topic} setTopic={setTopic} flag={flag} setFlag={setFlag} MainClassName={"image_upload"} />

        <PostCreate open={question} setTopic={setQuestion} flag={flag1} setFlag={setFlag1} MainClassName={"image_upload1"} />

        <ViewAllGroup open={viewGroup} setViewGroup={setViewGroup} />

        {openCrop && <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL }} profile={profile} />}

        {/* Mobile profile responsive  */}
        <div className="bg-primary h-[190px] relative sm:hidden">
          <div className="bg-black -bottom-3 absolute w-full h-[119px] rounded-t-[20px] sm:bg-[#000] flex items-center justify-center flex-col">
            {/* <div className="text-center -mt-[53px]">
              <div className="mx-auto w-[93px] border-solid border-4 border-white rounded-full relative" style={{ boxShadow: "0 4px 24px 0 rgba(0, 0, 0, 0.12)" }}>
                <img className="mx-auto w-full rounded-full" src={photoURL ? `data:image/jpeg;base64,${photoURL}` : (someProfile && someProfile !== "null") ? `data:image/jpeg;base64,${someProfile}` : logo4}/>
                <label htmlFor="profilePhoto">
                  <div className="bg-primary h-6 w-6 rounded-full flex items-center justify-center absolute bottom-1 -right-0" style={{ cursor: "pointer" }}>
                    <img src={CameraIcon} alt="camera" className="text-white text-xl" />
                    <input accept="image/*" id="profilePhoto" type="file" style={{ display: "none" }} onChange={handleChange} />
                  </div>
                </label>
              </div>
              </div> */}

              <p className="text-4 font-[400] text-[#707B88] mt-2">
                <span className="text-[#707B88]">Welcome,</span>
              </p>
              <h3 className="text-[18px] font-bold text-[#fff] -mt-1">{someValue ?? ""}</h3>
            </div>
          </div>
        </div>

        {/* home wraper */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          <div className="col-span-2">
            {/* home top */}
            <div className="bg-[#131313] sm:rounded-lg">
              {/* search bar */}
              <div className="flex items-center justify-between px-5 pt-8 pb-5 sm:pt-7 sm:pb-7  gap-4">
                <form className="w-full relative">
                  <input
                    placeholder="Search Topics"
                    type="text"
                    className="px-[14px] py-[9px] w-full h-[50px] pl-14 rounded-full searchArea1 text-white hover:bg-[#2a2a2a]"
                    onChange={(e) => HandleSearch(e)}
                  />
                  <button
                    type="submit"
                    className="w-[36px] h-[36px] flex items-center justify-center bg-primary rounded-full absolute top-1/2 left-7 -translate-x-1/2 -translate-y-1/2"
                  >
                    <a href="/">
                      <img src={SearchIconDark} alt="search icon" className="text-black text-xl" width={"auto"} height={"auto"} />
                    </a>
                  </button>

                  <button
                    type="submit"
                    className="w-[36px] h-[36px] flex items-center justify-center bg-primary rounded-full absolute top-1/2 -right-2 -translate-x-1/2 -translate-y-1/2 sm:hidden"
                    onClick={openPopUp}
                    style={{ cursor: "pointer" }}
                  >
                    <AiOutlinePlus className="text-black text-xl" />
                  </button>
                </form>

                <div className="hidden sm:flex w-[70px] h-[50px] bg-primary items-center justify-center rounded-full" onClick={() => token? setTopic(true):setMessageForLogin(!messageForLogin)} style={{ cursor: "pointer" }}>
                  <AiOutlinePlus className="text-black text-xl" />
                </div>
              </div>

              {/* divider */}
              <hr className="w-[95%] mx-auto " style={{ color: "rgba(255, 255, 255, 0.12)" }} />

              {/* {post create section} */}
              <div className=" flex items-center justify-around pt-8 pb-7 ">
                <div onClick={() => token?setTopic(true):setMessageForLogin(!messageForLogin)} className="flex-col sm:flex-row flex sm:flex items-center justify-center  cursor-pointer">
                  <img src={CreateTopicIcon} alt="create topic" className="text-primary text-2xl" width={"auto"} height={"auto"} />
                  <p className="text-sm sm:text-xl mt-1 sm:mt-0 sm:ml-3 text-[#7A7171]">
                    <span style={{ color: "#fff" }}>Create Post</span>
                  </p>
                </div>
                <div onClick={() => token?setQuestion(true):setMessageForLogin(!messageForLogin)} className="flex-col sm:flex-row flex sm:flex items-center justify-center" style={{ cursor: "pointer" }}>
                  <BsQuestionCircle className="text-primary text-2xl" />
                  <p className="text-sm sm:text-xl mt-1 sm:mt-0 sm:ml-3 text-[#7A7171]">
                    <span style={{ color: "#fff" }}>Ask Question</span>
                  </p>
                </div>
                <div onClick={() => token?setShowCreate(true):setMessageForLogin(!messageForLogin)} className="flex-col sm:flex-row flex sm:flex items-center justify-center cursor-pointer">
                  <img src={CreateGroupIcon} alt="create group icon" className="text-primary text-2xl" width={"auto"} height={"auto"} />
                  <p className="text-sm sm:text-xl mt-1 sm:mt-0 sm:ml-3 text-[#7A7171]">
                    <span style={{ color: "#fff" }}>Create Group</span>
                  </p>
                </div>
              </div>
            </div>

            {/* {Mochat post section} */}
            <Outlet />
          </div>

          {/* Right part mochat */}
          <div className="hidden sm:block">
            {/* create post & group */}
            <div className="w-full px-[49px] py-[37px] rounded-xl shadow-[0_4px_32px_rgba(0,0,0,0.08)]" style={{ backgroundColor: "#131313" }}>
              <div>
                <img src={companyLogo4} alt="logo" className="mx-auto" style={{ width: "42px", height: "41px" }} />
              </div>

              <div className="mt-6 createBtnSection">
                {token !== null || token ? (
                  <>
                    <button onClick={() => setTopic(true)} className="bg-primary w-full h-[50px] mb-4 text-white rounded-md flex items-center justify-center createPostBtn">
                      Create Post
                    </button>
                    <button
                      onClick={() => setShowCreate(true)}
                      className="bg-white text-[#000000] w-full h-[50px] border-solid border-2 border-[#000000] rounded-md  flex items-center justify-center createGroupBtn"
                    >
                      Create Group
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={() => navigate("/login")} className="bg-primary w-full h-[50px] mb-4 text-white rounded-md flex items-center justify-center text-black">
                      Login
                    </button>
                    <button
                      onClick={() => navigate("/signUp")}
                      className="bg-[transparent] text-[#000000] w-full h-[50px] border-solid border-2 border-[#000000] rounded-md  flex items-center justify-center"
                      style={{
                        border: "1px solid #ffa000",
                        color: "#ffa000",
                      }}
                    >
                      Sign Up
                    </button>
                  </>
                )}
              </div>
            </div>

            {/* Top Group section  */}
            <div>
              <div className="flex items-center justify-between my-7">
                <span className="w-[127] h-[38px] text-[#fff] text-[28px] font-[500]">Top Groups</span>
                <button className="bg-primary w-[110px] h-[36px] text-black rounded-md viewAllBtn" onClick={() =>token? setViewGroup(true):setMessageForLogin(!messageForLogin)}>
                  View All
                </button>
              </div>
              {Group&&Group?.map((ele,i)=>{
                return(
                  <>
                  <div className="flex items-center justify-between" key={i}>
                <div className="flex justify-between pb-5">
                  <div className="flex ">
                    <div className="w-1/8">
                      <img className="w-full h-[70px] rounded-xl" src={ele?.profilePicture?`data:image/jpeg;base64,${ele?.profilePicture}`:logo4} alt="logo" />
                    </div>
                    <div className="px-3">
                      <div className="flex items-center justify-between pb-1 text-gray">
                        <h3 className="text-[20px] font-medium  text-[#fff]">{ele?.name}</h3>
                      </div>
                      <p className="text-[18px] font-[300] pt-2 text-[#868B90]">{ele?.description}</p>
                    </div>
                  </div>
                </div>

                <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-9px] items-center justify-center rounded-full addGroupCircle">
                  <a href={token?"/":setMessageForLogin(!messageForLogin)}>
                    <AiOutlinePlus className="text-[#FEA500] text-xl" />
                  </a>
                </div>
              </div>

              {/* divider */}
              <hr className="w-[100%] mx-auto" style={{ color: "rgba(255, 255, 255, 0.12)" }} />
                  </>
                )
              })}
              

            </div>
            {/* <Footer /> */}

            <Message />
          </div>
        </div>
     
      {/* <Footer /> */}
    </React.StrictMode>
  );
};

export default Home;
