import React, { useEffect, useState } from 'react';

// redux toolkit
import { useDispatch, useSelector } from 'react-redux';

// react router
import { Link, useNavigate } from 'react-router-dom';

// components
import { BeforeLoginMessage } from '../components/Models/Index';
import { Header } from '../components';
import MochatPostSection from '../components/post/MochatPostSection';

// images
import { companyLogo4 } from '../constant/LogoPath';
import { movie1, movie2, movie3, movie4 } from '../constant/imagePath';

// react icon
import { AiOutlinePlus } from 'react-icons/ai';
import { get } from '../api/api';
import StorageService from '../../src/services/StorageService';
import { PostCreate, Message } from "../components/Models/Index";
import { startLoading, stopLoading } from '../Store/IdentitySlice';
import Footer from '../components/Footer/Footer';
// import { startLoading, stopLoading } from "../../Store/IdentitySlice";

const BeforeLoginView = () => {
    const dispatch = useDispatch();
    const [showCreate, setShowCreate] = useState(false);
    const [topic, setTopic] = useState(false);
    const [flag, setFlag] = useState(true);
    const [flag1, setFlag1] = useState(true);
    const [question, setQuestion] = useState(false);
    const [token, setToken] = useState("");
    const posts = useSelector((state) => state.posts);
    const data = posts.filter((val) => val.category === "TrendingPost");
    const [messageForLogin, setMessageForLogin] = useState(false);
    const navigate = useNavigate();
    const [TopPostData,setTopPostData]=useState([])
    useEffect(()=>{
        if(window?.location?.href?.split('/')[3]=="top-post"){
            getTopPosts()
        }else if (window?.location?.href?.split('/')[3]=="new-post"){
            getNewPosts()
        }else{
            getTrandingPosts()  
        }
    },[!TopPostData])

    useEffect(() => {
        setToken(StorageService.getJwt());
      }, [])

    const openPopUp = (e) => {
        e.preventDefault();
        setTopic(true);
      }

    // const getTrandingPosts= async()=>{
    //     let res =await get("/Post/TopVotedIn24Hours")
    //     setTopPostData(res)
    //     console.log("res111",res);
    //   }

    const getTrandingPosts = async()=>{ 
        dispatch(startLoading());
        try {
           let res = await get("/Post/TopVotedIn24Hours?pageNumber=0&pageSize=3&search=");
           console.log("res", res);
           
           if (res) {
            setTopPostData(res);
               dispatch(stopLoading());
           } else {
               dispatch(stopLoading());
           }
       } catch (error) {
            console.error('Error fetching top trending posts:', error);
            dispatch(stopLoading());
        }
    }

    const getTopPosts = async()=>{ 
        dispatch(startLoading());
        try {
           let res = await get("/Post/TopVotedPosts?pageNumber=0&pageSize=3&search=");
           console.log("res", res);
           if (res) {
            setTopPostData(res);
               dispatch(stopLoading());
           } else {
               dispatch(stopLoading());
           }
       } catch (error) {
            console.error('Error fetching top trending posts:', error);
            dispatch(stopLoading());
        }
    }
        
    // const getTopPosts= async()=>{
    //   let res =await get("/Post/TopVotedPosts")
    //   setTopPostData(res);
    // }
    // const getNewPosts= async()=>{
    //   let res =await get("/Post/ReadLatestPost")
    //   setTopPostData(res)
    //   console.log("res222",res);
    // }

    const getNewPosts = async()=>{ 
        dispatch(startLoading());
        try {
           let res = await get("/Post/ReadLatestPost?pageNumber=0&pageSize=3&search=");
           console.log("res", res);
           if (res) {
            setTopPostData(res);
               dispatch(stopLoading());
           } else {
               dispatch(stopLoading());
           }
       } catch (error) {
            console.error('Error fetching top trending posts:', error);
            dispatch(stopLoading());
        }
    }

    return (
        <React.StrictMode>
            {/* Header */}
            <Header loginAndSignup={StorageService.getJwt()?false:true} messageShow={false} setMessageForLogin={setMessageForLogin} />

            <BeforeLoginMessage helpModal={messageForLogin} setHelpModal={setMessageForLogin} />

            <div className="container mx-auto sm:mt-12 containerContent homePageStyle">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
                    <div className="col-span-2">
                        {TopPostData?.length > 0 ? (
                            TopPostData?.map((value, index) => (
                                <MochatPostSection key={index} data={value} homeView={true} />
                            ))
                        ) : (
                            <p className="empty">
                                No data found...
                            </p>
                        )}
                    </div>

                    {/* Right part mochat */}
                    <div className="hidden sm:block">
                        {/* create post & group */}
                        <div className="w-full px-[49px] py-[37px] rounded-xl shadow-[0_4px_32px_rgba(0,0,0,0.08)] bg-[#131313]">
                            <div>
                                <img
                                    src={companyLogo4}
                                    alt="logo"
                                    className="mx-auto"
                                    style={{ width: "42px", height: "41px" }}
                                />
                            </div>

                             <div className="mt-6 createBtnSection">
                                {token ? (<>
                                    <button
                                        onClick={() => setTopic(true)}
                                        className="bg-primary w-full h-[50px] mb-4 text-white rounded-md flex items-center justify-center createPostBtn"
                                    >
                                        Create Post
                                    </button><button
                                        onClick={() => setShowCreate(true)}
                                        className="bg-white text-[#000000] w-full h-[50px] border-solid border-2 border-[#000000] rounded-md  flex items-center justify-center createGroupBtn"
                                    >
                                            Create Group
                                        </button>
                                    </>) : ( 
                                    <><button onClick={() => navigate("/login")}
                                    className="bg-primary w-full h-[50px] mb-4 text-white rounded-md flex items-center justify-center text-black"
                                    >
                                    Login
                                    </button>
                                    <button
                                    onClick={() => navigate("/signUp")}
                                    className="bg-[transparent] text-[#000000] w-full h-[50px] border-solid border-2 border-[#000000] rounded-md  flex items-center justify-center"
                                    style={{
                                        border: "1px solid #ffa000",
                                        color: "#ffa000"
                                    }}
                                >
                                        Sign Up
                                    </button>
                                        </>)}
                            </div> 
                        </div>

                        <PostCreate
                            open={topic}
                            setTopic={setTopic}
                            flag={flag}
                            setFlag={setFlag}
                            MainClassName={"image_upload"}
                        />

                        <PostCreate
                            open={question}
                            setTopic={setQuestion}
                            flag={flag1}
                            setFlag={setFlag1}
                            MainClassName={"image_upload1"}
                        />

                        {/* Top Group section  */}
                        <div>
                            <div className="flex items-center justify-between my-7">
                                <span className="w-[127] h-[38px] text-[#fff] text-[28px] font-[500]">
                                    Top Groups
                                </span>
                                <button className="bg-primary w-[110px] h-[36px] text-black rounded-md viewAllBtn" onClick={() => setMessageForLogin(!messageForLogin)}>
                                    View All
                                </button>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex justify-between pb-5">
                                    <div className="flex ">
                                        <div className="w-1/8">
                                            <img
                                                className="w-full h-[70px] rounded-xl"
                                                src={movie1}
                                                alt="logo"
                                            />
                                        </div>
                                        <div className="px-3">
                                            <div className="flex items-center justify-between pb-1 text-gray">
                                                <h3 className="text-[20px] font-medium  text-[#fff]">
                                                    Cool Group Number 1
                                                </h3>
                                            </div>
                                            <p className="text-[18px] font-[300] pt-2 text-[#868B90]">
                                                Private · 1M members · 10+ posts a day
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-9px] items-center justify-center rounded-full" onClick={() => setMessageForLogin(!messageForLogin)} style={{ cursor: "pointer" }}>
                                    <AiOutlinePlus className="text-[#FEA500] text-xl" />
                                </div>
                            </div>

                            {/* divider */}
                            <hr className="w-[100%] mx-auto text-[#ffffff1f]" />

                            <div className="flex items-center justify-between">
                                <div className="flex justify-between py-5">
                                    <div className="flex ">
                                        <div className="w-1/8">
                                            <img
                                                className="w-full h-[70px] rounded-xl"
                                                src={movie2}
                                                alt="logo"
                                            />
                                        </div>
                                        <div className="px-3">
                                            <div className="flex items-center justify-between pb-1 text-gray">
                                                <h3 className="text-[20px] font-medium  text-[#fff]">
                                                    Cool Group Number 2
                                                </h3>
                                            </div>
                                            <p className="text-[18px] font-[300] pt-2 text-[#868B90]">
                                                Private · 1M members · 10+ posts a day
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-9px] items-center justify-center rounded-full" onClick={() => setMessageForLogin(!messageForLogin)} style={{ cursor: "pointer" }}>
                                    <AiOutlinePlus className="text-[#FEA500] text-xl" />
                                </div>
                            </div>

                            {/* divider */}
                            <hr className="w-[100%] mx-auto text-[#ffffff1f]" />

                            <div className="flex items-center justify-between">
                                <div className="flex justify-between py-5">
                                    <div className="flex ">
                                        <div className="w-1/8">
                                            <img
                                                className="w-full h-[70px] rounded-xl"
                                                src={movie3}
                                                alt="logo"
                                            />
                                        </div>
                                        <div className="px-3">
                                            <div className="flex items-center justify-between pb-1 text-gray">
                                                <h3 className="text-[20px] font-medium  text-[#fff]">
                                                    Cool Group Number 3
                                                </h3>
                                            </div>
                                            <p className="text-[18px] font-[300] pt-2 text-[#868B90]">
                                                Private · 1M members · 10+ posts a day
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-9px] items-center justify-center rounded-full" onClick={() => setMessageForLogin(!messageForLogin)} style={{ cursor: "pointer" }}>
                                    <AiOutlinePlus className="text-[#FEA500] text-xl" />
                                </div>
                            </div>

                            {/* divider */}
                            <hr className="w-full mx-auto text-[#ffffff1f]" />

                            <div className="flex items-center justify-between">
                                <div className="flex justify-between py-5">
                                    <div className="flex ">
                                        <div className="w-1/8">
                                            <img
                                                className="w-full h-[70px] rounded-xl"
                                                src={movie4}
                                                alt="logo"
                                            />
                                        </div>
                                        <div className="px-3">
                                            <div className="flex items-center justify-between pb-1 text-gray">
                                                <h3 className="text-[20px] font-medium  text-[#fff]">
                                                    Cool Group Number 4
                                                </h3>
                                            </div>
                                            <p className="text-[18px] font-[300] pt-2 text-[#868B90]">
                                                Private · 1M members · 10+ posts a day
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-9px] items-center justify-center rounded-full" onClick={() => setMessageForLogin(!messageForLogin)} style={{ cursor: "pointer" }}>
                                    <AiOutlinePlus className="text-[#FEA500] text-xl" />
                                </div>
                            </div>

                            {/* divider */}
                            <hr className="w-[100%] mx-auto text-[#ffffff1f]" />
                        </div>

                        <div className="flex items-center justify-center">
                            <Link
                                className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2]"
                                to="/Agreement"
                                reloadDocument={true}
                            >
                                User Agreement
                            </Link>
                            <span className="text-[#dddddd] mt-[21px]">|</span>
                            <Link
                                className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2]"
                                to="/PrivacyPolicy"
                                reloadDocument={true}
                            >
                                Privacy Policy
                            </Link>
                            <span className="text-[#dddddd] mt-[21px]">|</span>
                            <Link
                                className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2]"
                                to="/AcceptableUsePolicy"
                                reloadDocument={true}
                            >
                                Acceptable Use Policy
                            </Link>
                            <span className="text-[#dddddd] mt-[21px]">|</span>
                            <Link
                                className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2]"
                                to="/Disclaimer"
                                reloadDocument={true}
                            >
                                Disclaimer
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.StrictMode>
    )
}

export default BeforeLoginView;