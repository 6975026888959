import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { Help } from "../../components/Models/Index";
import CropEasy from "../../components/Crop/CropEasy";

// Images
import { logo4, profile } from '../../constant/imagePath';
import { CameraIcon, EyeIcon, HelpIcon, LogoutIcon, SettingIcon } from '../../constant/IconsPath';
import StorageService from '../../services/StorageService';

const LeftSideProfileSection = ({ imageURL, setImageURL }) => {
    const [photoURL, setPhotoURL] = useState();
    const [openCrop, setOpenCrop] = useState(false);
    const [helpModal, setHelpModal] = useState(false);
    const someValue = StorageService.getValue('userName');
    const someProfile = StorageService.getValueProfilePic('profilePic');
    const navigate = useNavigate();


    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setOpenCrop(true);
            if (imageURL) {
                setImageURL(URL.createObjectURL(file));
            } else {
                setPhotoURL(URL.createObjectURL(file));
            }
        } else {
            setOpenCrop(false);
            if (imageURL) {
                setImageURL();
            } else {
                setPhotoURL();
            }
        }
    };

    return (
        <React.StrictMode>
            {openCrop && (
                <CropEasy
                    {...{ photoURL: imageURL ?? photoURL, setOpenCrop, setPhotoURL: setImageURL ?? setPhotoURL }}
                    profile={profile}
                />
            )}

            <Help helpModal={helpModal} setHelpModal={setHelpModal} />

            <div className="bg-primary h-[210px] relative hidden sm:block">
                <div
                    className="bg-[#131313] -bottom-56 absolute w-full h-[284px] rounded-t-[20px] shadow-[0_4px_32px_rgba(0,0,0,0.08)]"
                    style={{ borderRadius: "30px 30px 0 0" }}
                >
                    <div className="text-center -mt-24 relative">
                        <div className="mx-auto w-[150px] h-[150px] border-solid border-[5px] border-white rounded-full relative ">
                            {/* <img
                                className="mx-auto w-full h-full rounded-full z-0"
                                // src={imageURL ?? photoURL ?? profile}
                                src={`data:image/jpeg;base64,${someProfile}`}
                                alt="Profile"
                            /> */}
                            <div className="w-50 h-50 sm:w-40 sm:h-40 rounded-full overflow-hidden relative " style={{ marginLeft: "-10px", marginTop: '-10px' }}>
                                <img
                                    className="w-40 h-40  rounded-full object-cover"
                                    src={someProfile !="null"?`data:image/jpeg;base64,${someProfile}`:logo4}
                                    alt="Profile"
                                />
                            </div>

                            {/* <label htmlFor="profilePhoto1">
                                <div
                                    className="bg-primary h-[30px] w-[30px] rounded-full flex items-center justify-center -mt-[50px] ml-[115px] absolute"
                                    style={{ cursor: "pointer" }}
                                >
                                    <img
                                        src={CameraIcon}
                                        alt="camera"
                                        className="text-white text-xl"
                                    />

                                    <input
                                        accept="image/*"
                                        id="profilePhoto1"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={handleChange}
                                    />
                                </div>
                            </label> */}
                        </div>

                        <div className="flex items-center justify-center mt-3">
                            <h3 className="text-[18px] font-bold text-[#fff] ">
                                {someValue}
                            </h3>
                        </div>
                        <div className="flex items-center justify-center mt-1">
                            <p className="text-4 font-[400] text-[#707b88] ">
                                {/* @sallyburns */}
                            </p>
                        </div>
                        <div className="font-bold py-3 flex text-primary text-[18px] items-center justify-center">
                            <p className="mr-6"><span className='text-[#fea500]'>0 Followers</span></p>
                            <p><span className='text-[#fea500]'>0 Following</span></p>
                        </div>
                        <div className="flex items-center justify-center">
                            <button
                                className="bg-primary rounded-md px-3 text-center flex items-center justify-center sm:h-[38px] text-black font-[400] text-[18px sm:-mr-0 ViewProfile"
                                style={{ minWidth: "200px" }}
                                onClick={() => navigate("/settings/accountInfo")}
                            >
                                <img
                                    src={EyeIcon}
                                    alt="eye icon"
                                    className="w-[18px] h-[18px] mr-1"
                                />
                                View Profile
                            </button>
                        </div>

                        {/* divider */}
                        <hr className="w-[90%] mt-5 mx-auto text-[#ffffff17]" />

                        {/* right profile view bottom section */}
                        <div className="flex items-center justify-between px-5 py-3">
                            <div
                                className="flex items-center justify-center text-[#868B90]"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/settings/accountInfo")}
                            >
                                <img
                                    src={SettingIcon}
                                    alt="setting"
                                    className="text-[#868B90] w-[22px] h[22px] mr-[2px]"
                                />
                                <p
                                    className="text-[18px] font-normal "
                                    style={{ paddingLeft: "0.3rem" }}
                                >
                                    Settings
                                </p>
                            </div>
                            <div
                                className="flex items-center justify-center text-[#868B90]"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setHelpModal(!helpModal);
                                }}
                            >
                                <img
                                    src={HelpIcon}
                                    alt="help icon"
                                    className="text-[#868B90] w-[22px] h[22px]  mr-[2px]"
                                />
                                <p
                                    className="text-[18px] font-normal"
                                    style={{ paddingLeft: "0.3rem" }}
                                >
                                    Help
                                </p>
                            </div>
                            <div
                                className="flex items-center justify-center text-[#868B90]"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/login")}
                            >
                                <img
                                    src={LogoutIcon}
                                    alt="logout"
                                    className="text-[#868B90] w-[22px] h[22px]  mr-[2px]"
                                />
                                <p
                                    className="text-[18px] font-normal "
                                    style={{ paddingLeft: "0.3rem" }}
                                    onClick={() => localStorage.clear()}
                                >
                                    Log Out
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.StrictMode>
    )
}

export default LeftSideProfileSection;