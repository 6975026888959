import React, { useState, useRef, useEffect } from "react";

// React Router
import { Link, useLocation, useNavigate } from "react-router-dom";

// redux toolkit
import { addParent } from "../Store/MessagesSlice";
import { useDispatch, useSelector } from "react-redux";

// images
import { companyLogo4 } from "../constant/LogoPath";
import {
  logo4,
  movie1,
  movie2,
  movie3,
  movie4,
  profile1,
} from "../constant/imagePath";
import { VerticalDotsWhiteIcon, LeftArrowIcon } from "../constant/IconsPath";
import background1 from "../assets/img/image 91.webp";

// react icons
import { AiOutlinePlus } from "react-icons/ai";

// Components
import GroupCreate from "../components/Models/GroupCreate";
import { PostCreate, ConfirmationDialogBox } from "../components/Models/Index";
import Header from "../components/Header/Header";
import MochatPostSection from "../components/post/MochatPostSection";
import CropEasy from "../components/Crop/CropEasy";
import ViewAllGroup from "../components/Models/ViewAllGroup/ViewAllGroup";

// style
import "../style/UserProfile.css";
import Footer from "../components/Footer/Footer";
import { post } from "../api/api";
import { updateTrendingPostDataOnSearch } from "../Store/TrendingPageSlice";
import { startLoading, stopLoading } from "../Store/IdentitySlice";

const UserProfile = () => {
  const posts = useSelector((state) => state.posts);
  const data = posts.filter((val) => val.category === "TrendingPost");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log("state",state);
  const messages = useSelector((state) => state.messages);

  const [showCreate, setShowCreate] = useState(false);
  const [topic, setTopic] = useState(false);
  const [photoURL, setPhotoURL] = useState();
  const [openCrop, setOpenCrop] = useState(false);
  const [flag, setFlag] = useState(true);
  const [flag1, setFlag1] = useState(false);
  const [viewGroup, setViewGroup] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [userDetail, setUserDetail] = useState({});
console.log("userDetail",userDetail);
  const BlockUser = useRef();
  const {postData} = useSelector((state) => state.Trending);
  console.log("postData",postData);
  useEffect(()=>{
    if(state){
      getUserDetailById(state)
      getTrandingPosts(state)
    }
  },[state])
 
  const getTrandingPosts = async (id) => {
    dispatch(startLoading());

    try {
      const res= await post(`/Post/getuserPostbyId?userId=${id}`)
      console.log("res", res);
      let data = await res.json();
      if (res && data) {
        // setPostData(data);
        console.log(data, "datadatadatadata");
        dispatch(updateTrendingPostDataOnSearch(data));
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
      }
    } catch (error) {
      console.error("Error fetching trending posts:", error);
      dispatch(stopLoading());
    }
  };
  const getUserDetailById= async(id)=>{
    const res= await post(`/Identity/GetUser?userId=${id}`)
    let data=await res.json();
    setUserDetail(data)
    }

  useEffect(() => {
    const handler1 = (e) => {
      if (BlockUser.current && !BlockUser.current.contains(e.target)) {
        setFlag1(false);
      }
    };
    document.addEventListener("mousedown", handler1);
    return () => {
      document.removeEventListener("mousedown", handler1);
    };
  }, []);
 

  const sucessFullyBlockUser = () => {
    console.log("Successfully block user");
    setConfirmationMessage(false);
  };
  const cancelBlockUser = () => {
    console.log("Successfully cancel action to block a user");
    setConfirmationMessage(false);
  };

  const GoToMessagePage = () => {
    let data = {
      id: messages.length + 1,
      image: photoURL ?? state?.img ?? profile1,
      name: state?.name ?? "Solly Sherman-Burns",
      message: "Hey bro! I just messaged..",
      time: "7:29 P.M",
      status: "Online",
      follower: state?.followers ?? 250,
      following: state?.following ?? 20,
      post: state?.post ?? 9,
    };
    dispatch(addParent(data));
    navigate(`/messages/${messages.length + 1}`);
  };

  return (
    <React.StrictMode>
      <Header loginAndSignup={false} messageShow={true} />
      <div className="container mx-auto containerContent homePageStyle">
        <GroupCreate open={showCreate} setShowCreate={setShowCreate} titleShow={true} userNameShow={true} />
        <ViewAllGroup open={viewGroup} setViewGroup={setViewGroup} />

        <PostCreate
          open={topic}
          setTopic={setTopic}
          flag={flag}
          setFlag={setFlag}
          MainClassName={"image_upload"}
        />

        {openCrop && (
          <CropEasy
            {...{ photoURL, setOpenCrop, setPhotoURL }}
            profile={profile1}
          />
        )}
        <ConfirmationDialogBox
          comment={"Are you sure you want to block this person?"}
          buttonText1={"Yes"}
          buttonText2={"Cancel"}
          firstBTNFN={sucessFullyBlockUser}
          secondBTNFN={cancelBlockUser}
          setConfirmationMessage={setConfirmationMessage}
          confirmationMessage={confirmationMessage}
        />

        {/* Profile section wraper */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          <div className="col-span-2 ">
            {/* top banner profile section  */}
            <div
              className="bg-profileBanner1 h-[230px] bg-center bg-cover bg-no-repeat"
              style={{
                backgroundImage: `url(${userDetail?.user?.coverPicture?`data:image/png;base64,${userDetail?.user?.coverPicture}`: background1
                  })`,
                backgroundPosition: "top",
              }}
            >
              <div className="w-full h-[119px] rounded-t-[20px]">
                {/* banner top text  */}
                <div
                  className="flex items-center justify-between userProfileTopBannerPart"
                  style={{ padding: "0.8rem 1rem", paddingBottom: 0 }}
                >
                  <div className="flex items-center justify-center">
                    <img
                      src={LeftArrowIcon}
                      alt="Left Arrow Icon"
                      className="text-white h-[22px] w-[22px] mr-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(-1)}
                    />
                    <h3 className="text-[22px] font-bold text-white top_banner_left_side_text">
                      {userDetail?.user?.userName ?? "Solly Sherman-Burns"}
                    </h3>
                  </div>
                  <div
                    className="relative"
                    style={{ cursor: "pointer" }}
                    ref={BlockUser}
                  >
                    <img
                      src={VerticalDotsWhiteIcon}
                      alt="vertical white icon"
                      onClick={() => setFlag1(!flag1)}
                    />

                    {flag1 && (
                      <div
                        className="option"
                        style={{
                          top: "2.3rem",
                          right: "-18px",
                          minHeight: "auto",
                        }}
                        onClick={() => {
                          setFlag1(!flag1);
                          setConfirmationMessage(!confirmationMessage);
                        }}
                      >
                        <div className="option1">
                          <p>Block User</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="block sm:flex items-start justify-start">
              {/* profile image */}
              <div className="w-[150px] h-[150px] bg-white p-[5px] mx-auto sm:mx-[34px] rounded-full -mt-[77px] reponsiveDesignUserProfile">
                <img
                  className="mx-auto w-full h-full rounded-full z-0"
                  src={photoURL?photoURL:userDetail?.user?.profilePicture?`data:image/png;base64,${userDetail?.user?.profilePicture}`:logo4}
                  alt="Profile"
                />
              </div>

              {/* profile content */}
              <div className="mt-4 bottomContent">
                <div className="userProfileContentResponsiveDesign">
                  <div className="flex items-center justify-center sm:justify-between">
                    <h3
                      className="text-[20px] font-bold text-[#fff]"
                    >
                      {userDetail?.user?.userName ?? "Solly Sherman-Burns"}
                    </h3>
                  </div>

                  <p className="text-sm sm:text-lg text-center sm:text-left leading-loose pt-5 pb-9 mx-5 sm:mx-0 sm:pr-8 font-[400] text-[#636363] bioStylingResponsive">
                    {userDetail?.user?.bio ??
                      "They say money can’t buy happiness… but it can buy yoga classes. And that’s basically the same thing. If you want to know the correct way to perform an exercise, the answer is: Whatever hurts most."}
                  </p>

                  {/* provile activity */}
                  <div className="font-bold flex items-center justify-around sm:justify-start sm:gap-6">
                    <div
                      className="flex items-center justify-between flex-col sm:flex-row"
                      style={{ color: "rgba(68, 68, 68, 1)" }}
                    >
                      <span className="text-base sm:text-lg -mb-1 sm:-m-0 sm:mr-1 text-[#FEA500]">
                        {userDetail?.postCount||0}
                      </span>
                      <span className="text-base sm:text-lg text-[#ffffff80]">Posts</span>
                    </div>

                    <div
                      className="flex items-center justify-between flex-col sm:flex-row"
                      style={{ color: "rgba(68, 68, 68, 1)" }}
                    >
                      <span className="text-base sm:text-lg -mb-1 sm:-m-0 sm:mr-1 text-[#FEA500]">
                        {state?.followers ?? 0}
                      </span>
                      <span className="text-base sm:text-lg text-[#ffffff80]">Followers</span>
                    </div>

                    <div
                      className="flex items-center justify-between flex-col sm:flex-row"
                      style={{ color: "rgba(68, 68, 68, 1)" }}
                    >
                      <span className="text-base sm:text-lg -mb-1 sm:-m-0 sm:mr-1 text-[#FEA500]">
                        {state?.following ?? 0}
                      </span>
                      <span className="text-base sm:text-lg text-[#ffffff80]">Following</span>
                    </div>
                  </div>
                </div>

                {/* button */}
                {/* <div className="flex items-center justify-around sm:justify-start pt-6 sm:pt-4 gap-4 sm:gap-3 mx-5 sm:mx-0 btnResponsiveDesign">
                  <button className="px-[49px] py-[9px] bg-primary text-black  rounded-md text-[18px] w-full sm:w-auto" style={{ fontWeight: 600 }}>
                    Follow
                  </button>

                  <button
                    className="px-10 py-2 border-primary text-primary rounded-md border-solid border-[0.9px] text-[18px] font-bold w-full sm:w-auto"
                    onClick={GoToMessagePage}
                  >
                    Message
                  </button>
                </div> */}
              </div>
            </div>

            {/* divider */}
            <hr className="w-[100%] mt-8 mx-auto text-[#ffffff1f]" />

            {/* {Mochat post section} */}
            {postData?.length > 0 &&
              postData?.map((value, index) => {
                return (
                  <React.StrictMode key={index}>
                    <MochatPostSection data={value} addMargin={true} url={true} disableThreeDot={true} />
                  </React.StrictMode>
                );
              })}
          </div>

          {/* Right part mochat */}
          <div className="hidden mt-5 sm:block">
            {/* create post & group */}
            <div className="w-full px-[49px] py-[37px] rounded-xl shadow-[0_4px_32px_rgba(0,0,0,0.08)]" style={{ backgroundColor: "#131313" }}>
              <div>
                <img
                  src={companyLogo4}
                  alt="logo"
                  className="mx-auto"
                  style={{ width: "42px", height: "41px" }}
                />
              </div>

              <div className="mt-6 createBtnSection">
                <button
                  onClick={() => setTopic(true)}
                  className="bg-primary w-full h-[50px] mb-4 text-white rounded-md flex items-center justify-center createPostBtn"
                >
                  Create Post
                </button>

                <button
                  onClick={() => setShowCreate(true)}
                  className="bg-white text-[#000000] w-full h-[50px] border-solid border-2 border-[#000000] rounded-md  flex items-center justify-center createGroupBtn"
                >
                  Create Group
                </button>
              </div>
            </div>

            {/* Top Group section  */}
            <div>
              <div className="flex items-center justify-between my-7">
                <span className="w-[127] h-[38px] text-[#fff] text-[28px] font-[500]">
                  Top Groups
                </span>
                <button className="bg-primary w-[110px] h-[36px] text-black rounded-md viewAllBtn" onClick={() => setViewGroup(true)}>
                  View All
                </button>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex justify-between pb-5">
                  <div className="flex ">
                    <div className="w-1/8">
                      <img
                        className="w-full h-[70px] rounded-xl"
                        src={movie1}
                        alt="logo"
                      />
                    </div>
                    <div className="px-3">
                      <div className="flex items-center justify-between pb-1 text-gray">
                        <h3 className="text-[20px] font-medium  text-[#fff]">
                          Cool Group Number 1
                        </h3>
                      </div>
                      <p className="text-[18px] font-[300] pt-2 text-[#868B90]">
                        Private · 1M members · 10+ posts a day
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-9px] items-center justify-center rounded-full addGroupCircle">
                  <a href="/">
                    <AiOutlinePlus className="text-[#FEA500] text-xl" />
                  </a>
                </div>
              </div>

              {/* divider */}
              <hr className="w-[100%] mx-auto" style={{ backgroundColor: "rgba(255, 255, 255, 0.12)" }} />

              <div className="flex items-center justify-between">
                <div className="flex justify-between py-5">
                  <div className="flex ">
                    <div className="w-1/8">
                      <img
                        className="w-full h-[70px] rounded-xl"
                        src={movie2}
                        alt="logo"
                      />
                    </div>
                    <div className="px-3">
                      <div className="flex items-center justify-between pb-1 text-gray">
                        <h3 className="text-[20px] font-medium  text-[#fff]">
                          Cool Group Number 2
                        </h3>
                      </div>
                      <p className="text-[18px] font-[300] pt-2 text-[#868B90]">
                        Private · 1M members · 10+ posts a day
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-7px] items-center justify-center rounded-full addGroupCircle">
                  <a href="/">
                    <AiOutlinePlus className="text-[#FEA500] text-xl" />
                  </a>
                </div>
              </div>

              {/* divider */}
              <hr className="w-[100%] mx-auto" style={{ backgroundColor: "rgba(255, 255, 255, 0.12)" }} />

              <div className="flex items-center justify-between">
                <div className="flex justify-between py-5">
                  <div className="flex ">
                    <div className="w-1/8">
                      <img
                        className="w-full h-[70px] rounded-xl"
                        src={movie3}
                        alt="logo"
                      />
                    </div>
                    <div className="px-3">
                      <div className="flex items-center justify-between pb-1 text-gray">
                        <h3 className="text-[20px] font-medium  text-[#fff]">
                          Cool Group Number 3
                        </h3>
                      </div>
                      <p className="text-[18px] font-[300] pt-2 text-[#868B90]">
                        Private · 1M members · 10+ posts a day
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-7px] items-center justify-center rounded-full addGroupCircle">
                  <a href="/">
                    <AiOutlinePlus className="text-[#FEA500] text-xl" />
                  </a>
                </div>
              </div>

              {/* divider */}
              <hr className="w-full mx-auto" style={{ backgroundColor: "rgba(255, 255, 255, 0.12)" }} />

              <div className="flex items-center justify-between">
                <div className="flex justify-between py-5">
                  <div className="flex ">
                    <div className="w-1/8">
                      <img
                        className="w-full h-[70px] rounded-xl"
                        src={movie4}
                        alt="logo"
                      />
                    </div>
                    <div className="px-3">
                      <div className="flex items-center justify-between pb-1 text-gray">
                        <h3 className="text-[20px] font-medium  text-[#fff]">
                          Cool Group Number 2
                        </h3>
                      </div>
                      <p className="text-[18px] font-[300] pt-2 text-[#868B90]">
                        Private · 1M members · 10+ posts a day
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-[36px] h-[36px] bg-[#131313] flex mt-[-7px] items-center justify-center rounded-full addGroupCircle">
                  <a href="/">
                    <AiOutlinePlus className="text-[#FEA500] text-xl" />
                  </a>
                </div>
              </div>

              {/* divider */}
              <hr className="w-[100%] mx-auto" style={{ backgroundColor: "rgba(255, 255, 255, 0.12)" }} />
            </div>

            {/* <div className="flex items-center justify-center">
              <Link
                className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                to="/Agreement"
                reloadDocument={true}
              >
                User Agreement
              </Link>
              <span className="text-[#dddddd] mt-[21px]">|</span>
              <Link
                className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                to="/PrivacyPolicy"
                reloadDocument={true}
              >
                Privacy Policy
              </Link>
              <span className="text-[#dddddd] mt-[21px]">|</span>
              <Link
                className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                to="/AcceptableUsePolicy"
                reloadDocument={true}
              >
                Acceptable Use Policy
              </Link>
              <span className="text-[#dddddd] mt-[21px]">|</span>
              <Link
                className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                to="/Disclaimer"
                reloadDocument={true}
              >
                Disclaimer
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </React.StrictMode>
  );
};

export default UserProfile;
