import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// redux toolkit
import { Provider, useSelector } from "react-redux";
import Store from "./Store/Store";

import { AdminCommunityGroup, Agreement, ContentPolicy, Home, Login, PageNotFound, PrivacyPolicy, SignUp, TermsAndConditions, UserProfile, UserProfileView, Disclaimer } from "./pages";

import { AccountInfo, ChangePassword, EditProfile, EmailAndNotifications, LoginSessions, Settings, Subscription, TwoFactorAuthentication } from "./pages/Settings";
import ResetPasswordForm from "./components/Forms/ResetPasswordForm/ResetPasswordForm";
import ResetPasswordFormLink from "./components/Forms/ResetPasswordFormLink/ResetPasswordLinkForm";

import OTPVerification from "./components/Forms/OTPVerification/OTPVerification";
import ActivationSuccessfulPage from "./pages/ActivationSuccessfulPage";
import AccountNotActivatedPage from "./pages/AccountNotActivated";
import ActivationLinkSentPage from "./pages/ActivationLinkSentPage";
import ProcessActivation from "./pages/ProcessActivation";
import PasswordChangedSuccessful from "./pages/PasswordChangedSuccessful";
import LinkExpiredPage from "./pages/LinkExpiredPage";

// Messages
import Messages from "./pages/Message/Messages";
import MessageBox from "./components/Messages/MessageBox";

// post topic
import TrendingPage from "./pages/MochatPostSection/TrendingPage";
import TopPostPage from "./pages/MochatPostSection/TopPostPage";
import NewPostPage from "./pages/MochatPostSection/NewPostPage";
import ForYouPage from "./pages/MochatPostSection/ForYouPage";

import Post from "./pages/userProfileView/Post";
import Saved from "./pages/userProfileView/Saved";
import Groups from "./pages/userProfileView/Groups";
import SearchResult from "./pages/SearchResult/SearchResult";

// Notification
import NotificationPage from "./pages/Notification/NotificationPage";

// before login home view
import BeforeLoginView from "./pages/BeforeLoginView";

import MediaPage from "./pages/Media/MediaPage";
import MediaContentPage from "./pages/Media/MediaContentPage";
// Google Login
import { GoogleOAuthProvider } from "@react-oauth/google";
import Loader from "./components/Loader/Loader";
import ViewPost from "./pages/viewpost/ViewPost";
import Cookies from "./pages/Cookies";

function App() {
  const { isLoading } = useSelector((state) => state.identity);
  const adminCommunityGroupProps = {
    TrendingPost: "/adminCommunityGroup",
    topPost: "/adminCommunityGroup/top-post",
    newPost: "/adminCommunityGroup/new-post",
    forYou: "/adminCommunityGroup/for-you",
  };
  return (
    <div className="App h-full">
      {/* <Provider store={Store}>
      <GoogleOAuthProvider clientId={ClientID}> */}
      {isLoading && <Loader />}
      <Router>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<TrendingPage />} />
            <Route path="/top-post" element={<TopPostPage />} />
            <Route path="/new-post" element={<NewPostPage />} />
            <Route path="/for-you" element={<ForYouPage />} />
          </Route>
          {/* <Route path="/" element={<Home />}>
              <Route index element={<TrendingPage />} />
            </Route> */}
          <Route path="/media" element={<MediaPage />}>
            <Route path=":title" element={<MediaContentPage />} />
          </Route>
          <Route path="/homeView" element={<BeforeLoginView />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/viewpost" element={<ViewPost />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/Agreement" element={<Agreement />} />
          <Route path="/Cookies" element={<Cookies />} />
          <Route path="/AcceptableUsePolicy" element={<ContentPolicy />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/Disclaimer" element={<Disclaimer />} />
          <Route path="/userProfile" element={<UserProfile />} />
          <Route path="/password-reset-link" element={<ResetPasswordFormLink />} />
          <Route path="/password-reset-form" element={<ResetPasswordForm />} />
          <Route path="/otp-verification" element={<OTPVerification />} />
          <Route path="/account-not-activated" element={<AccountNotActivatedPage />} />
          <Route path="/activation-link-sent" element={<ActivationLinkSentPage />} />
          <Route path="/process-activation/:token" element={<ProcessActivation />} />
          ProcessActivation
          <Route path="/link-expired" element={<LinkExpiredPage />} />
          <Route />
          <Route path="/activation-successful" element={<ActivationSuccessfulPage />} />
          <Route path="/password-successful" element={<PasswordChangedSuccessful />} />
          <Route path="/adminCommunityGroup" element={<AdminCommunityGroup />}>
            <Route index element={<TrendingPage {...adminCommunityGroupProps} />} />
            <Route path="top-post" element={<TopPostPage {...adminCommunityGroupProps} />} />
            <Route path="new-post" element={<NewPostPage {...adminCommunityGroupProps} />} />
            <Route path="for-you" element={<ForYouPage {...adminCommunityGroupProps} />} />
          </Route>
          <Route path="/userProfileView" element={<UserProfileView />}>
            <Route index element={<Post />} />
            <Route path="saved" element={<Saved />} />
            <Route path="groups" element={<Groups />} />
          </Route>
          {/* Nested Routes */}
          <Route path="/settings" element={<Settings />}>
            <Route index element={<AccountInfo />} />
            <Route path="accountInfo" element={<AccountInfo />} />
            <Route path="editProfile" element={<EditProfile />} />
            <Route path="emailAndNotifications" element={<EmailAndNotifications />} />
            <Route path="subscription" element={<Subscription />} />
            <Route path="security/changePassword" element={<ChangePassword />} />
            <Route path="security/twoAuthentication" element={<TwoFactorAuthentication />} />
            <Route path="security/loginSessions" element={<LoginSessions />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          {/* Messages */}
          <Route path="/messages" element={<Messages />}>
            <Route path="/messages/:id" element={<MessageBox />} />
          </Route>
          <Route path="search-result" element={<SearchResult />} />
          <Route path="notifications" element={<NotificationPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      {/* </GoogleOAuthProvider>
      </Provider> */}
    </div>

    // <>
    //   {/* <ResetPasswordFormLink /> */}
    //   {/* <Success></Success> */}
    //   {/* <OTPVerification /> */}
    //   {/* <ResetPasswordForm /> */}
    // </>
  );
}

export default App;
