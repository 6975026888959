import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import MochatPostSection from "../../components/post/MochatPostSection";
import {
  ForYouIcon,
  NewTopicIcon,
  TrendingTopicIcon,
} from "../../constant/IconsPath";
import { BsStar } from "react-icons/bs";
import { post } from "../../api/api";
import { updateTrendingPostData } from "../../Store/TrendingPageSlice";
import { SyncLoader } from "react-spinners";
const ForYouPage = ({ TrendingPost, topPost, newPost, forYou }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postData,search } = useSelector((state) => state.Trending);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(true);
  console.log("postData",postData);
    useEffect(() => {
      dispatch(updateTrendingPostData({ data:[], route: "For" }));
      const delay = setTimeout(() => {
        getNewPosts(page)
        setNoData(false)
      }, 2000);
      return () => clearTimeout(delay);
    }, [dispatch]);

    const sectionRef = useRef();
    const datas = useRef(postData);
    const pageNo = useRef(page);
    datas.current = postData;
    pageNo.current = page;
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          const totalCount = [...new Set(datas?.current?.map((item)=> item?.totalRecordCount))];
          console.log("totalCount",totalCount[0]);
          if (entry.isIntersecting && !loading && pageNo.current < Math?.ceil(totalCount[0] / 3)) {
            getNewPosts(pageNo.current + 1);
            setPage((prevPage) => prevPage + 1);
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.5,
        }
      );
  
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }
  
      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }, []);

    const getNewPosts = async (pageNumber) => {
      setLoading(true)
      try {
        let res = await post(`/Post/GetUserGroupData?pageNumber=${pageNumber-1}&pageSize=3&search=${search?.searchValue}`);
        let data = await res.json();
        console.log("res", data);
        if (res && data) {
          dispatch(updateTrendingPostData({ data, route: "For" }));
          const timer = setTimeout(() => {
            setLoading(false);
          }, 500);
          return () => clearTimeout(timer);
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.error("Error fetching top trending posts:", error);
        setLoading(false)
      }
    };
  const renderPosts = () => {
    if (loading||noData) {
      return null; 
    } else if (postData?.length === 0) {
        if (!loading) {
          return <p className="empty flex items-center justify-center">You need to join a group in order to see for you posts...</p>;
        }
    }
  };

  return (
    <React.StrictMode>
      <div className="flex items-center justify-around sm:justify-start py-7 sm:gap-7 btnSection">
        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[70px] sm:w-[145px] h-[30px] sm:h-10"
          onClick={() => navigate(TrendingPost ?? "/")}
        >
          <TrendingTopicIcon fill={"#fff"} />
          <span className="text-primary font-primaryFont font-normal ml-1 hidden sm:block">
            Trending Topic
          </span>
        </button>

        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[70px] sm:w-[145px] h-[30px] sm:h-10"
          onClick={() => navigate(topPost ?? "/top-post")}
        >
          <BsStar className="text-[#fff] text-lg" fill="#fff" />
          <span className="text-[#fff] font-primaryFont font-normal ml-1 hidden sm:block">
            Top
          </span>
        </button>

        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[70px] sm:w-[145px] h-[30px] sm:h-10"
          onClick={() => navigate(newPost ?? "/new-post")}
        >
          <NewTopicIcon fill="#fff" />
          <span className="text-[#fff] font-primaryFont font-normal ml-1 hidden sm:block">
            New
          </span>
        </button>

        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[70px] sm:w-[145px] h-[30px] sm:h-10 active"
          onClick={() => navigate(forYou ?? "/for-you")}
        >
          <ForYouIcon fill="#fff" />
          <span className="text-[#fff] font-primaryFont font-normal ml-1 hidden sm:block">
            For You
          </span>
        </button>
      </div>
      {postData?.length > 0 &&
        postData.map((value, index) => {
          return (
            <React.StrictMode key={index}>
              <MochatPostSection data={value} />
            </React.StrictMode>
          );
        })}
        <div ref={sectionRef} className="flex justify-center items-center">{loading&&<SyncLoader color="#fea500" loading={true} height={15} width={5} />}</div>
        {renderPosts()}
    </React.StrictMode>
  );
};

export default ForYouPage;
