import React from "react";
import { Header } from "../components";
import AccountActivation from "../components/AccountActivation/AccountActivation";
import { time } from "../constant/imagePath";
import axios from "axios";
import { toast } from "react-toastify";
import StorageService from "../services/StorageService";


const LinkExpiredPage = () => {


  const handleResendEmail = async () => {
    const value = StorageService.getValue("email");
    console.log("Resend Api call")
    try {
      // Call your API here for resending email
      const response = await axios.post(`${StorageService?.baseURL}/Identity/ResendEmail?email=${value}`);
      console.log("Response:", response);

      // Show success message if API call succeeds
      toast.success("Email resent successfully.");
    } catch (error) {
      // Show error message if API call fails
      console.error("Error:", error);
      toast.error("Failed to resend email. Please try again.");
    }
  };

  let heading = "Link Expired";
  let content = (
    <p style={{ padding: "1.5rem 0" }}>
      For security reasons, activation links are only valid for 24 hours. And
      unfortunately, your activation link has expired.
    </p>
  );
  let btnConfig = [
    { id: 1, name: "Resend Email", noBg: true,onClick: handleResendEmail }, 
    { id: 2, name: "Login to another account", to: "/login" },
  ];

  return (
    <>
      <Header loginAndSignup={true} messageShow={false} />

      <AccountActivation
        heading={heading}
        content={content}
        btnConfig={btnConfig}
        img={time}
        Margin="0rem auto"
        boxWidth={"590px"}
      />
    </>
  );
};

export default LinkExpiredPage;
