import React, { useEffect } from "react";

// component
import MochatPostSection from "../../components/post/MochatPostSection";

// images
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "../../api/api";
import { updateTrendingPostDataOnSearch } from "../../Store/TrendingPageSlice";
import { startLoading, stopLoading } from "../../Store/IdentitySlice";

const Post = () => {

  const {postData} = useSelector((state) => state.Trending);
  console.log("postData",postData);
  const dispatch = useDispatch();
  useEffect(()=>{
    getTrandingPosts()
  },[])
  const getTrandingPosts = async () => {
    dispatch(startLoading());

    try {
      let res = await post(`/Post/ReadUserPosts?pageNumber=1&pageSize=10`);
      console.log("res", res);
      let data = await res.json();
      if (res && data) {
        // setPostData(data);
        console.log(data, "datadatadatadata");
        dispatch(updateTrendingPostDataOnSearch(data));
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
      }
    } catch (error) {
      console.error("Error fetching trending posts:", error);
      dispatch(stopLoading());
    }
  };

  const navigate = useNavigate();

  return (
    <React.StrictMode>
      {/* // {top 5 button section} */}
      <div className="flex items-center justify-around sm:justify-start py-8 sm:gap-4 BtnGroupStyle">
        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[90px] sm:w-[145px] h-[30px] sm:h-10
                buttonAddBorder"
          onClick={() => navigate("/userProfileView")}
        >
          <span className="text-primary font-primaryFont font-normal sm:block">
            Posts
          </span>
        </button>

        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[90px] sm:w-[145px] h-[30px] sm:h-10"
          onClick={() => navigate("/userProfileView/saved")}
        >
          <span className="text-white font-primaryFont font-normal sm:block">
            Saved
          </span>
        </button>

        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[90px] sm:w-[145px] h-[30px] sm:h-10"
          onClick={() => navigate("/userProfileView/groups")}
        >
          <span className="text-white font-primaryFont font-normal sm:block">
            Groups
          </span>
        </button>
      </div>
      {/* {Mochat post section} */}
      {postData?.length > 0 &&
        postData?.map((value, index) => {
          return (
            <React.StrictMode key={index}>
              <MochatPostSection data={value} addMargin={false} url={true}/>
            </React.StrictMode>
          );
        })}{postData?.length===0&&<p className="empty">No post found...</p>}
    </React.StrictMode>
  );
};

export default Post;
