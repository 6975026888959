import { createSlice } from "@reduxjs/toolkit";

const TrendingInitialState = {
  postData: [],
  route:"/",
  search:{ searchValue: " ", isSearched: false }
};

const TrendingPageSlice = createSlice({
  name: "Trending",
  initialState: TrendingInitialState,
  reducers: {
    updateTrendingPostData(state, action) {
      const newData = action.payload.data;
      const newRoute = action.payload.route;
      
      if (state.route === newRoute) {
        console.log("inside-------------------->");
        const uniquePosts = [...new Set([...state.postData, ...newData])];
        state.postData = uniquePosts;
      } else {
        console.log("inside out-------------------->");
        state.postData = newData;
        state.route = newRoute;
      }
      
    },
    updateTrendingPostDataOnSearch(state, action) {
        state.postData = action.payload;
    },
    setSearch(state, action) {
        state.search = action.payload;
    },
  },
});

export const { updateTrendingPostData,updateTrendingPostDataOnSearch,setSearch } = TrendingPageSlice.actions;

export default TrendingPageSlice.reducer;
