import React from "react";
import classes from "./ConfigurePasswordBtn.module.css";

const ConfigurePasswordBtn = (props) => {
  const { title = "Testing" } = props;
  return (
    <button className={classes["config-btn"]} style={{marginTop:"20px"}}>
      {title}
    </button>
  );
};

export default ConfigurePasswordBtn;
