import { toast } from 'react-toastify';
import { post, get } from '../api/api';
import StorageService from './StorageService';

export const login = async (username, password,setTwofa) => {
    const loginRequest =
    {
        "EmailOrUserName": username,
        "Password": password
    };
    const loginResponse = await post('/identity/login', loginRequest);
    const responseValue = await loginResponse.json();
    console.log("responseValue", responseValue);
    if (responseValue.error) {
        toast.error(responseValue?.message)
        return false
    } else {
        StorageService.setValue("userName",responseValue?.userName);
        StorageService.setValue("userId",responseValue?.userId);
        StorageService.setValueProfile("profilePic",responseValue?.profilePicture);
        setTwofa(responseValue?.isTwoFactorEnabled)
        StorageService.setValue("QRCode",responseValue?.qrCodeUrl);
        StorageService.setValue("secretKey",responseValue?.tfaKey);
        StorageService.setJwt(responseValue?.token)
        if(responseValue?.isTwoFactorEnabled){
            return false;
        } else {
            toast.success("Login successfully!")
            return true;
        }
    }
};

export const register = async (username, email, password, dateOfBirth) => {
    const registerRequest =
    {
        "userName": username,
        "dateOfBirth": dateOfBirth,
        "email": email,
        "password": password,
        "confirmPassword": password
    };

    const registerResponse = await post('/identity/register', registerRequest);

    const outcome =
    {
        "success": registerResponse.ok,
        "message": await registerResponse.text()
    };

    return outcome;
};

export const processActivation = async (token) => {
    const registerResponse = await post('/identity/verify?token=' + token);
    const outcome =
    {
        "success": registerResponse.ok,
        "message": await registerResponse.text()
    };
    return outcome;
};
