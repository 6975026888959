import React, { useRef } from "react";

// react router
import { useNavigate } from "react-router-dom";

// Styling
import "../../style/BeforeLoginMessage.css";

// font awesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const BeforeLoginMessage = ({ helpModal, setHelpModal }) => {
    const modalRef = useRef();
    const navigate = useNavigate();

    function resetAllValue() {
        setHelpModal(false);

        const closeIconStyle = document.querySelector(".closeIconStyle");
        if (closeIconStyle) {
            closeIconStyle.style.display = "none";
        }
    }

    function onFunction(e) {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            resetAllValue();
        }
    }

    return (
        <div
            className={"postCreate helpModal"}
            style={{
                opacity: helpModal ? 1 : 0,
                cursor: helpModal ?? "none",
                zIndex: helpModal ? 1111111111111 : -1,
                visibility: helpModal ? "visible" : "hidden"
            }}
            onClick={onFunction}
        >
            <div className="center_div" ref={modalRef}>
                {/* top part */}
                <div className="post_create_top_part">
                    <p></p>
                    {/* close icon */}
                    <div className="close_icon" onClick={resetAllValue}>
                        <FontAwesomeIcon icon={faXmark} />
                    </div>
                </div>

                {/* main part */}
                <div className="main_part">
                    {/* text area */}
                    <div className="text_area">
                        <p>
                            <span className="text-white">
                            Please log in first. If you don't have an account, you'll need to create one to use this feature.
                            </span>
                        </p>


                        {/* button */}
                        <div className="btn_group" style={{ marginTop: "2rem" }}>
                            <button
                                onClick={() => navigate("/login")}
                                className="bg-primary w-full h-[50px] mb-4 text-black rounded-md flex items-center justify-center"
                            >
                                Login
                            </button>

                            <button
                                onClick={() => navigate("/signUp")}
                                className="bg-[transparent] text-[#ffa000] w-full h-[50px] rounded-md flex items-center justify-center"
                                style={{ border: "1px solid rgb(255, 160, 0)" }}
                            >
                                Sign Up
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BeforeLoginMessage;
