import { createSlice } from "@reduxjs/toolkit";

const identitySlice = createSlice({
    name: "identity",
    initialState: {
        signUp:
            {
                username: "garry bollox",
                emailAddress: "bollox@garry.com",
            },
            isLoading:false
    },
    reducers: {
        addRegistration(state, action) {
            const {username, emailAddress} = action.payload;
            const signUpData = state.signUp;
            signUpData.username = username;
            signUpData.emailAddress = emailAddress;
        },
        startLoading(state){
            state.isLoading=true
        },
        stopLoading(state){
            state.isLoading=false
        },

    },
});

export const {
    addRegistration,startLoading,stopLoading
} = identitySlice.actions;

export default identitySlice.reducer;