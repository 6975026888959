import React, { useState } from "react";
import axios from "axios"; // Import Axios library
import { useLocation } from "react-router";

// Style
import classes from "./ResetPasswordForm.module.css";

// Components
import CardDiv from "../../UI/CardDiv/CardDiv";
import FormContent from "../FormContent/FormContent";
import FormInput from "../FormInput/FormInput";
import ConfigurePasswordBtn from "../ConfigurePasswordBtn/ConfigurePasswordBtn";
import GetTheApp from "../GetTheApp/GetTheApp";
import Header from "../../Header/Header";
import { toast } from "react-toastify";
import StorageService from "../../../services/StorageService";


const ResetPasswordForm = () => {
  const location = useLocation();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const token = location?.search?.split("=")[1];

  // Regex pattern for password validation
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,]).{8,}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setNewPasswordError("");
    setConfirmPasswordError("");

    if (!newPassword) {
      setNewPasswordError("Please enter a new password.");
      return;
    }
    if (!passwordRegex.test(newPassword)) {
      setNewPasswordError("Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character.");
      return;
    }
    if (!confirmPassword) {
      setConfirmPasswordError("Please confirm your password.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    }
    try {
      // Making POST request to the API
      const response = await axios.post(`${StorageService?.baseURL}/Identity/ResetPassword`, {
        Token: token,
        Password: newPassword,
        PasswordReset: confirmPassword,
      });
      console.log("response", response);
      toast.success("Password reset successfully!");
      
      setTimeout(() => {
      window.location.href = "/login";
      }, 2000);
    } catch (error) {
      toast.error(error?.response?.data);
    }
  };

  return (
    <React.StrictMode>
      <Header loginAndSignup={true} messageShow={false} sarchBarShow={false} />
      <CardDiv Margin="0rem auto">
        <form onSubmit={handleSubmit}>
          <FormContent
            heading="Reset Your Password"
            content="Reset your password in a few simple steps."
          />

          <div className={`${classes["form-inputs"]} mt-2rem`}>
            <FormInput
              label="New Password"
              id="new-password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              newPasswordError={newPasswordError}
            />
            <br />
            <FormInput
              label="Confirm Password"
              id="confirm-password"
              type="password"
              eyeShow={false}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              confirmPasswordError={confirmPasswordError}
            />
          </div>
          <ConfigurePasswordBtn title="Reset My Password" />
          <GetTheApp isCreateAccount={false} />
        </form>
      </CardDiv>
      {error && <span style={{ color: "red" }}>{error}</span>}
      {successMessage && <div className="success-message">{successMessage}</div>}
    </React.StrictMode>
  );
};

export default ResetPasswordForm;
