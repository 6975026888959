const StorageService = {
  setValue: (key, value) => {
    localStorage.setItem(key, value);
  },
  setValueProfile: (key, value) => {
    localStorage.setItem(key, value);
  },

  getValue: (key) => {
    return localStorage.getItem(key);
  },
  getValueProfilePic: (key) => {
    return localStorage.getItem(key);
  },
  setJwt: (token) => {
    StorageService.setValue("620ffc0b-fb61-478c-ab4c-c9e8270f6152", token);
  },

  getJwt: () => {
    return StorageService.getValue("620ffc0b-fb61-478c-ab4c-c9e8270f6152");
  },
  //  baseURL : 'https://0365-2405-201-5004-7827-8834-26a9-963a-4368.ngrok-free.app/api' // ng rok
  baseURL: "https://betaapi.mochatforum.com/api", // live
};

export default StorageService;
