import React, { useEffect, useRef, useState } from "react";
import { register } from "../services/IdentityService";

// React Router
import { Link, useNavigate } from "react-router-dom";

// redux toolkit
import { addRegistration } from "../Store/IdentitySlice";
import { useDispatch, useSelector } from "react-redux";
import GoogleAuthentication from "./GoogleAuthentication/GoogleAuthentication";

// images
import { google, googlePlay, appStore } from "../constant/imagePath";

// component
import { Help } from "../components/Models/Index";
import { Header } from "../components";

// css
import classes from "../style/SignUp.module.css";
import "../style/SignUpStyle.css";

// icon
import { AiOutlineInfo } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { toast } from "react-toastify";
import Loader from "../components/Loader/Loader";
import { startLoading,stopLoading } from "../Store/IdentitySlice";

import StorageService from "../services/StorageService";
const SignUp = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState(false);
  const [ageInstruction, setAgeInstruction] = useState(false);
  const [flag, setFlag] = useState(false);
  const [userInfo, setUserInfo] = useState({
    userName: "",
    email: "",
    dateOfBirth: "",
    password: "",
  });
  const [valueCheck, setValueCheck] = useState(false);
  const [email, setEmail] = useState();
  const [dateOfBirthMsg, setDateOfBirthMsg] = useState();
  const [password, setPassword] = useState();
  const [confirmPasswordMsg, setConfirmPasswordMsg] = useState();
  const [isEyeOpen, setIsEyeOpen] = useState(faEyeSlash);
  const [fieldValue, setFieldValue] = useState({
    email: false,
    dateOfBirth: false,
    password: false,
    passwordMatch: false,
  });
  const [dateErrMsg, setdateErrMsg] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [msg, setmsg] = useState(false);
  const [dateInfo, setdateInfo] = useState(false);;

  useEffect(() => {
    let currentyear = new Date().getFullYear();

    console.log(new Date(currentyear).getFullYear, "current");
  }, []);

  const maxDate = new Date().getFullYear() - 18;


  const handleDateChange = (date) => {
    const data = new Date(date).getFullYear();
    if (maxDate >= data) {
      setdateErrMsg("");
      setmsg(true);
    } else {
      setdateErrMsg("You have to be over the age of 18 to create an account!");
      setmsg(false);
      // alert("under age")
    }
    setSelectedDate(date);
  };

  const bindingWithEmailField = useRef("");
  const confirmPassword = useRef("");
  const inputRef = useRef();

  const navigate = useNavigate();

  const signInFunction = (e) => {
    e.preventDefault();
  };

  const eyeIcon = () => {
    if (inputRef.current.type === "password") {
      inputRef.current.type = "text";
      setIsEyeOpen(faEye);
    } else {
      inputRef.current.type = "password";
      setIsEyeOpen(faEyeSlash);
    }
  };

  function validation(e) {
    e.preventDefault();
    let { email, password, passwordMatch } = fieldValue;
    if (
      userInfo.userName &&
      email &&
      password &&
      passwordMatch &&
      selectedDate &&
      msg
    ) {
      tryRegisterUser();
    } else {
      // if (selectedDate == undefined) {
      //   setdateErrMsg("Required");
      // } else {
      //   setdateErrMsg("");
      // }
      setValueCheck(true);
      Datevalidation();
      emailCheck();
      passwordCheck();
      passwordRecheck();
    }
  }

  const Datevalidation = () => {
    if (
      selectedDate == undefined ||
      selectedDate == "" ||
      selectedDate == null
    ) {
      // setSelectedDate("Required");
      setdateErrMsg("Date of birth is required fields!");
    } else if(msg) {
      // setSelectedDate("");
      setdateErrMsg("");
    }
  };

  function tryRegisterUser() {
    StorageService.setValue("email",userInfo.email)
    dispatch(startLoading())
    register(userInfo.userName, userInfo.email, userInfo.password, selectedDate)
      .then((result) => {
        if (result?.success) {
          dispatch(
            addRegistration({
              username: userInfo.userName,
              emailAddress: userInfo.email,
            })
          );
          dispatch(stopLoading())
          navigate("/activation-link-sent");
        } else {
          dispatch(stopLoading())
          toast.error(result?.message);
        }
      })
      .catch((result) => {
        toast.error(result?.message);
      });
  }

  function emailCheck() {
    let inputValue = bindingWithEmailField.current.value;
    if (inputValue === "") {
      setEmail("Please enter the Email.");
    } else if (!inputValue.includes("@") || !inputValue.endsWith(".com")) {
      setEmail("Email provided is invalid, please check and try again.");
    } else {
      setEmail("");
      setFieldValue({ ...fieldValue, email: true });
    }
  }

  function passwordCheck() {
    const specialCharRegex = /[^\w\d]+/;
    const numberRegex = /\d/;
    const characterRegex = /[A-Z]/;
    const minLengthRegex = /^.{8,}$/;
    if (inputRef.current.value === "") {
      setPassword("Please enter the password.");
    } else if (!characterRegex.test(inputRef.current.value)) {
      setPassword("Password must contain Upper case letter..");
    } else if (!numberRegex.test(inputRef.current.value)) {
      setPassword("Password must contain Numbers.");
    } else if (!specialCharRegex.test(inputRef.current.value)) {
      setPassword("Password must contain Special Character.");
    } else if (!minLengthRegex.test(inputRef.current.value)) {
      setPassword("Password Must be 8 Characters Long.");
    } else {
      setPassword("");
      setFieldValue({ ...fieldValue, password: true });
    }
  }

  function passwordRecheck() {
    let inputValue = confirmPassword.current.value;
    if (inputValue === "") {
      setConfirmPasswordMsg("Please enter the password again.");
    } else if (inputValue !== userInfo.password) {
      setConfirmPasswordMsg("Password does not match.");
    } else {
      setConfirmPasswordMsg();
      setFieldValue({ ...fieldValue, passwordMatch: true });
    }
  }

  


  return (
    <React.StrictMode>
      <Header loginAndSignup={true} messageShow={false} />

      
      <Help
        helpModal={message}
        setHelpModal={setMessage}
        justShow={"Mobile App will be launched soon."}
      />

      <div className="sm:w-[540px] w-full mx-auto sm:pt-20 pt-8 containerContent">
        <h1 className="text-[28px] text-secondary font-bold sm:mx-0 mx-5">
          Create Account
        </h1>
        <h2 className="text-xl text-secondary sm:mx-0 mx-5">
          Join Mochat Today!
        </h2>
        <form action="#" className="mt-5 sm:mx-0 mx-5">
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
            
            <div className="fullNameField">
              <label className="text-gray block" htmlFor="firstName">
                Username <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="name"
                id="firstName"
                className="border border-gray rounded-md h-[34px] w-full px-2 inputField"
                onChange={(e) =>
                  setUserInfo({
                    ...userInfo,
                    userName: e.target.value.replace(/\s+/g, " ").trim(),
                  })
                }
                style={{
                  borderColor:
                    valueCheck && userInfo.userName === "" && "#ff3b3b",
                }}
              />
              {valueCheck && userInfo.userName === "" && (
                <p>
                  <span
                    style={{
                      fontSize: "1rem",
                      color: "#ff3b3b",
                      fontWeight: 100,
                    }}
                  >
                    Please enter the username.
                  </span>
                </p>
              )}
            </div>

            <div>
              <label className="text-gray block" htmlFor="email">
                Email <span style={{ color: "red" }}> *</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                ref={bindingWithEmailField}
                className="border border-gray rounded-md h-[34px] w-full px-2 inputField"
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    email: e.target.value.replace(/\s+/g, " ").trim(),
                  });
                  emailCheck();
                }}
                required
                style={{ borderColor: email && "#ff3b3b" }}
              />
              {email && (
                <p>
                  <span
                    style={{
                      fontSize: "1rem",
                      color: "#ff3b3b",
                      fontWeight: 100,
                    }}
                  >
                    {email}
                  </span>
                </p>
              )}
            </div>

            <div className={`${classes["form-control"]} relative`}>
              <label className="text-gray block relative" htmlFor="lastName">
                Date of birth (MM/DD/YY){" "}
                <span style={{ color: "red" }}> *</span>
                <AiOutlineInfo
                  className="h-[16px] w-[16px] rounded-full text-[#000] bg-[#D1D1D1] sm:inline infoIcon"
                  onMouseOver={() => setdateInfo(true)}
                  onMouseOut={() => setdateInfo(false)}
                />
                
                {dateInfo && (
                  <div className="infoStyle" style={{ top: "-3.1rem" }}>
                    <p>
                      <span> Kindly Please Select the date</span>
                    </p>
                  </div>
                )}
              </label>
              <div style={{ width: "50%" }}>
                <DatePicker
                  onChange={handleDateChange}
                  value={selectedDate}
                  calendarClassName="custom-calendar" // Custom class for the calendar
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    width: "100%",
                  }}
                  // maxDate={maxDate} // Users can't select future dates
                />
              </div>

              {/* <input
                type="date"
                name="date"
                id="date"
                className="border border-gray rounded-md h-[34px] w-full px-2 inputField dateOfBirth"
                onChange={(e) => {
                  setUserInfo({ ...userInfo, dateOfBirth: e.target.value });
                  dateOfBirthCheck();
                }}
                required
                // style={{ borderColor: dateOfBirthMsg && "#ff3b3b", color: "#fff" }}
                ref={bindingWithDateOfBirth}
              /> */}
              {dateErrMsg && (
                <p>
                  <span
                    style={{
                      fontSize: "1rem",
                      color: "#ff3b3b",
                      fontWeight: 100,
                    }}
                  >
                    {dateErrMsg}
                  </span>
                </p>
              )}
            </div>

            <div>
              <div className={`${classes["form-control"]} relative`}>
                <label htmlFor="password" style={{ color: "#848b90" }}>
                  Password <span style={{ color: "red" }}> *</span>
                  <AiOutlineInfo
                    className="h-[16px] w-[16px] rounded-full text-[#000] bg-[#D1D1D1] sm:inline infoIcon"
                    onMouseOver={() => setFlag(true)}
                    onMouseOut={() => setFlag(false)}
                  />
                  
                  {flag && (
                    <div className="infoStyle" style={{ top: "-3.1rem" }}>
                      <p>
                        <span>
                          Password Must be 8 Characters Long And Contain:
                        </span>

                        <br />

                        <span
                          style={{
                            marginTop: "0.5rem",
                            display: "inline-block",
                          }}
                        >
                          1{")"} Upper case letter <br />2{")"} Numbers <br />3
                          {")"} Special Character
                        </span>
                      </p>
                    </div>
                  )}
                </label>

                <input
                  type="password"
                  id="password"
                  ref={inputRef}
                  className="border border-gray rounded-md h-[34px] w-full px-2 inputField"
                  onChange={(e) => {
                    setUserInfo({
                      ...userInfo,
                      password: e.target.value.replace(/\s+/g, " ").trim(),
                    });
                    passwordCheck();
                  }}
                  style={{
                    borderColor: password && "#ff3b3b",
                    padding: "0.25rem 35px 0.25rem 0.5rem",
                    borderRadius: "6px",
                  }}
                />
                <FontAwesomeIcon
                  icon={isEyeOpen}
                  onClick={eyeIcon}
                  style={{
                    position: "absolute",
                    right: "0.6rem",
                    bottom: "0.6rem",
                    color: "#ff9600",
                    fontSize: ".9rem",
                  }}
                />
              </div>

              {password && (
                <p>
                  <span
                    style={{
                      fontSize: "1rem",
                      color: "#ff3b3b",
                      fontWeight: 100,
                    }}
                  >
                    {password}
                  </span>
                </p>
              )}
            </div>

            <div>
              <label className="text-gray block" htmlFor="ConfirmPassword">
                Confirm password <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="password"
                name="name"
                id="ConfirmPassword"
                className="border border-gray rounded-md h-[34px] w-full px-2 inputField"
                ref={confirmPassword}
                onChange={passwordRecheck}
                style={{ borderColor: confirmPasswordMsg && "#ff3b3b" }}
              />
              {confirmPasswordMsg && (
                <p>
                  <span
                    style={{
                      fontSize: "1rem",
                      color: "#ff3b3b",
                      fontWeight: 100,
                    }}
                  >
                    {confirmPasswordMsg}
                  </span>
                </p>
              )}
            </div>

            
          </div>

          

          <div className={classes["signup-description"]}>
            <p>
              By clicking Sign Up, you agree to our
              <strong style={{ cursor: "pointer" }}>
                {" "}
                <Link to="/Agreement" reloadDocument={true}>
                  User Agreement,
                </Link>{" "}
                <Link to="/PrivacyPolicy" reloadDocument={true}>
                  Privacy Policy
                </Link>
              </strong>{" "}
              and
              <strong style={{ cursor: "pointer" }}>
                {" "}
                <Link to="/AcceptableUsePolicy" reloadDocument={true}>
                  Acceptable Use Policy.
                </Link>
              </strong>
            </p>
          </div>

          
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-5 groupOFButton">
            <button
              // className="bg-primary text-black py-[10px] rounded-md text-xl"
              className="commonButton bg-primary text-black rounded-md text-xl"
              onClick={validation}
            >
              Create Account
            </button>

            {/* <button
                                className="bg-secondary text-white py-[10px] rounded-md text-xl flex items-center justify-center"
                                onClick={signInFunction}
                            >
                                <img className="mr-2" src={google} alt="google"/>
                                
                                <GoogleAuthentication />
                            </button> */}
            <button
              type="button"
              // className="bg-secondary text-white py-[10px] rounded-md text-xl flex items-center justify-center"
              className="commonButton bg-secondary text-white rounded-md text-xl flex items-center justify-center"
            >
              <GoogleAuthentication />
            </button>
          </div>
        </form>

        
        <div className="mt-4">
          <p
            className="mb-5 text-lg text-center"
            style={{ fontWeight: "300", color: "rgba(255, 255, 255, 0.7)" }}
          >
            Already have an account?{" "}
            <Link
              className="text-primary"
              to="/login"
              style={{ fontWeight: "400" }}
            >
              log in
            </Link>
          </p>

          <hr style={{ color: "rgba(255, 255, 255, 0.12)" }} />

          <p className="text-gray text-base text-center mt-5 font-light">
            Get The App
          </p>

          <div className="flex items-center justify-center gap-2 mt-2 bottomCompanyIcon">
            <img
              src={googlePlay}
              alt="google play"
              style={{ cursor: "pointer" }}
              onClick={() => setMessage(!message)}
            />
            <img
              src={appStore}
              alt="app store"
              style={{ cursor: "pointer" }}
              onClick={() => setMessage(!message)}
            />
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
};
export default SignUp;
